import {ItemDto} from './item-dto';
import {MeasureType} from '../type/measure-type';

export class MeasureItemDto extends ItemDto {

	constructor(public id: number|string,
				public name: Map<number, string>,
				public measureType: MeasureType) {
		super(id, name);
	}
}
