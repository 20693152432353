import {AbstractCriterionValues} from './abstract-criterion-values';
import {CriterionHelper} from '../helper/criterion-helper';

/**
 * For standard or timeline category 'in', 'unspecified' etc.
 */
export class CriterionNumbers extends AbstractCriterionValues<number> {

	public get values(): Array<number> {
		return this.getValues();
	}

	public set values(values: Array<number>) {
		this.setValues(CriterionHelper.ifNumbers(values));
	}

}
