import {FilterDtoBuilder} from '../builder/filter-dto-builder';
import {FilterDto} from './filter-dto';

/**
 * Patterned search filter. Selects values sets which match the pattern <CODE>.*value.*</CODE>
 *
 * Created by Hayk.Andriasyan on 6/1/2017.
 */
export class ContainsFilterDto extends FilterDto {
	public static readonly CONTAINS: string = 'contains';

	/**
	 *  category or measure item dto id
	 */
	public readonly itemId: number|string;

	/**
	 * The value used in the pattern
	 */
	public readonly value: string;

	public constructor(itemId: number|string, value: string) {
		super(FilterDtoBuilder.FILTER, ContainsFilterDto.CONTAINS);
		this.itemId = itemId;
		this.value = value;
	}
}
