import {AbstractCriterionValue} from './abstract-criterion-value';
import {CriterionHelper} from '../helper/criterion-helper';

/**
 * For timeline category 'current', 'last', 'next' etc.
 */
export class CriterionNumber extends AbstractCriterionValue<number> {

	public get value(): number {
		return this.getValue();
	}

	public set value(value: number) {
		this.setValue(CriterionHelper.ifNumber(value));
	}

}
