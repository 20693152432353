import {FilterDto} from './filter-dto';

/**
 * Base class for measure filter DTOs.
 *
 * Created by Hayk.Andriasyan on 5/23/2017.
 */
export abstract class AbstractMeasureFilterDto extends FilterDto {

	/**
	 * Measure item holder id on which this filter is applied on.
	 */
	public readonly onMeasureItemId: number|string;

	/**
	 * Category item holder by which measure filter should be resolved(implemented)
	 */
	public readonly byCategoryItemId: number|string;

	/**
	 * Currency id for money measure item holders.
	 */
	public readonly currencyId: number;

	public constructor(type: string, filterType: string, onMeasureItemId: number|string, byCategoryItemId: number|string, currencyId: number) {
		super(type, filterType);
		this.onMeasureItemId = onMeasureItemId;
		this.byCategoryItemId = byCategoryItemId;
		this.currencyId = currencyId;
	}
}
