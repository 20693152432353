import {ItemDto} from './item-dto';
import {CategoryType} from '../type/category-type';
import {DateCategoryType} from '../type/date-category-type';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';

export class CategoryItemDto extends ItemDto {

	constructor(public id: number|string,
				public name: Map<number, string>,
				public categoryType: CategoryType,
				public dateCategoryType?: DateCategoryType,
				public searchable?: boolean,
				public searchCategoryName?: string,
				public searchCategoryFields?: string[]) {
		super(id, name);
		if (categoryType === CategoryType.DATE) {
			PreconditionCheck.notNullOrUndefined(dateCategoryType);
		}
		if (searchable) {
			PreconditionCheck.notNullOrUndefined(searchCategoryName);
			PreconditionCheck.notNullOrUndefined(searchCategoryFields);
		}
	}
}
