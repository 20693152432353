import {FilterDtoBuilder} from './filter-dto-builder';
import {FilterDto} from '../dto/filter-dto';
import {MeasureRangeFilterDto} from '../dto/measure-range-filter-dto';
import {BooleanExpression} from './boolean-expression';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {ExactFilterDto} from '../dto/exact-filter-dto';
import {ContainsFilterDto} from '../dto/contains-filter-dto';
import {StartsWithFilterDto} from '../dto/starts-with-filter-dto';
import {EndsWithFilterDto} from '../dto/ends-with-filter-dto';

/**
 * Created by Hayk.Andriasyan on 5/25/2017.
 */
export class MeasureItem<T extends BooleanExpression> {

	private filter: FilterDto;
	private measureItemId: number|string;
	private parentOperator: T;
	private isExcluded = false;
	private isHidden = false;

	constructor(parentOperator: T, measureItemId: number|string) {
		this.parentOperator = parentOperator;
		this.measureItemId = measureItemId;
	}

	private static _formatDate(date: Date): string {
		const day = ('0' + date.getDate()).slice(-2);
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const year = date.getFullYear();

		return `${year}-${month}-${day}`;
	}

	addFilterToParentOperator(): void {
		this.parentOperator.addFilter(this.getFilter());
	}

	getFilter(): FilterDto {
		this.filter.excluded = this.isExcluded;
		this.filter.isHiddenForUser = this.isHidden;
		return this.filter;
	}

	/* tslint:disable:unified-signatures */
	is(value: string): T;
	is(value: number): T;
	is(value: Date): T;
	// is(value: number, byCategoryItemId: number): T
	is(value: number, currencyId: number): T;
	is(value: number, currencyId: number, byCategoryItemId: number|string): T;
	is(value: any, currencyId?: number, byCategoryItemId?: number|string): T {
		PreconditionCheck.notNullOrUndefined(value);
		let filterValue: string;
		let filterCurrencyId: number = null;
		let filterByCategoryItemId: number|string = null;

		switch (typeof value) {
			case 'string':
				filterValue = value;
				break;
			case 'object':
				if (value instanceof Date) {
					filterValue = MeasureItem._formatDate(value);
				}
				break;
			case 'number':
				filterValue = value.toString();
				break;
		}

		if (typeof currencyId === 'number') {
			filterCurrencyId = currencyId;
		}

		if (typeof byCategoryItemId !== 'undefined') {
			filterByCategoryItemId = byCategoryItemId;
		}

		const filter: ExactFilterDto = new ExactFilterDto(this.measureItemId, filterValue, filterCurrencyId, filterByCategoryItemId);

		return this._getParentOperator(filter);
	}

	/* tslint:enable:unified-signatures */

	contains(pattern: string): T {
		PreconditionCheck.notNullOrUndefined(pattern);
		const filter: ContainsFilterDto = new ContainsFilterDto(this.measureItemId, pattern.trim());
		return this._getParentOperator(filter);
	}

	startsWith(prefix: string): T {
		PreconditionCheck.notNullOrUndefined(prefix);
		const filter: StartsWithFilterDto = new StartsWithFilterDto(this.measureItemId, prefix.trim());
		return this._getParentOperator(filter);
	}

	endsWith(suffix: string): T {
		PreconditionCheck.notNullOrUndefined(suffix);
		const filter: EndsWithFilterDto = new EndsWithFilterDto(this.measureItemId, suffix.trim());
		return this._getParentOperator(filter);
	}

	/* tslint:disable:unified-signatures */
	between(from: number, to: number): T;
	between(from: Date, to: Date): T ;
	between(from: number, to: number, currencyId: number): T ;
	between(from: number, to: number, currencyId: number, byCategoryItemId: number|string): T ;
	between(from: any, to: any, currencyId?: number, byCategoryItemId?: number|string): T {
		PreconditionCheck.notNullOrUndefined(from);
		PreconditionCheck.notNullOrUndefined(to);

		const inclusive = true;
		let filterType = '';
		let valueFrom = '';
		let valueTo = '';
		let filterCurrencyId: number = null;
		let filterByCategoryItemId: number = null;

		if (typeof from === 'number') {
			filterType = FilterDtoBuilder.BETWEEN;
			valueFrom = from.toString();
			valueTo = to.toString();
		} else {
			filterType = FilterDtoBuilder.BETWEEN_DATES;
			valueFrom = MeasureItem._formatDate(from);
			valueTo = MeasureItem._formatDate(to);
		}

		if (typeof currencyId === 'number') {
			filterCurrencyId = currencyId;
		}

		if (typeof byCategoryItemId === 'number') {
			filterByCategoryItemId = byCategoryItemId;
		}

		const filter: MeasureRangeFilterDto = new MeasureRangeFilterDto(filterType, this.measureItemId,
			valueFrom, valueTo, inclusive, filterCurrencyId, filterByCategoryItemId);

		return this._getParentOperator(filter);
	}

	/* tslint:enable:unified-signatures */

	lessThan(value: number, currencyId?: number, byCategoryItemId?: number|string): T {
		PreconditionCheck.notNullOrUndefined(value);
		return this._comparingFilter(FilterDtoBuilder.LESS_THAN, false, null, value.toString(), currencyId, byCategoryItemId);
	}

	lessThanOrEqual(value: number, currencyId?: number, byCategoryItemId?: number|string): T {
		PreconditionCheck.notNullOrUndefined(value);
		return this._comparingFilter(FilterDtoBuilder.LESS_THAN_OR_EQUAL, true, null, value.toString(), currencyId, byCategoryItemId);
	}

	greaterThan(value: number, currencyId?: number, byCategoryItemId?: number|string): T {
		PreconditionCheck.notNullOrUndefined(value);
		return this._comparingFilter(FilterDtoBuilder.GREATER_THAN, false, value.toString(), null, currencyId, byCategoryItemId);
	}

	greaterThanOrEqual(value: number, currencyId?: number, byCategoryItemId?: number|string): T {
		PreconditionCheck.notNullOrUndefined(value);
		return this._comparingFilter(FilterDtoBuilder.GREATER_THAN_OR_EQUAL, true, value.toString(), null, currencyId, byCategoryItemId);
	}

	before(start: Date): T {
		PreconditionCheck.notNullOrUndefined(start);
		const filter: MeasureRangeFilterDto = new MeasureRangeFilterDto(FilterDtoBuilder.BEFORE, this.measureItemId,
			null, MeasureItem._formatDate(start), false, null, null);
		return this._getParentOperator(filter);
	}

	after(end: Date): T {
		PreconditionCheck.notNullOrUndefined(end);
		const filter: MeasureRangeFilterDto = new MeasureRangeFilterDto(FilterDtoBuilder.AFTER, this.measureItemId,
			MeasureItem._formatDate(end), null, false, null, null);

		return this._getParentOperator(filter);
	}

	onOrBefore(start: Date): T {
		PreconditionCheck.notNullOrUndefined(start);
		const filter: MeasureRangeFilterDto = new MeasureRangeFilterDto(FilterDtoBuilder.ON_OR_BEFORE, this.measureItemId,
			null, MeasureItem._formatDate(start), true, null, null);

		return this._getParentOperator(filter);
	}

	onOrAfter(end: Date): T {
		PreconditionCheck.notNullOrUndefined(end);
		const filter: MeasureRangeFilterDto = new MeasureRangeFilterDto(FilterDtoBuilder.ON_OR_AFTER, this.measureItemId,
			MeasureItem._formatDate(end), null, true, null, null);

		return this._getParentOperator(filter);
	}


	not(): MeasureItem<T> {
		this.isExcluded = !this.isExcluded;
		return this;
	}

	isHiddenFromUser(isHidden: boolean): MeasureItem<T> {
		this.isHidden = isHidden;
		return this;
	}

	private _getParentOperator(filter: FilterDto): T {
		this.filter = filter;
		this.addFilterToParentOperator();
		return this.parentOperator;
	}

	private _comparingFilter(filterType: string, inclusive: boolean, valueFrom: string, valueTo: string, currencyId?: number, byCategoryItemId?: number|string): T {

		let filterCurrencyId: number = null;
		let filterByCategoryItemId: number|string = null;

		if (typeof currencyId !== 'undefined') {
			filterCurrencyId = currencyId;
		}

		if (typeof byCategoryItemId !== 'undefined') {
			filterByCategoryItemId = byCategoryItemId;
		}

		const filter: MeasureRangeFilterDto = new MeasureRangeFilterDto(filterType, this.measureItemId, valueFrom,
			valueTo, inclusive, filterCurrencyId, filterByCategoryItemId);

		return this._getParentOperator(filter);
	}
}
