import {FilterTreeOperatorDto} from './filter-tree-operator-dto';
import {FilterTreeDtoFactory} from './filter-tree-dto-factory';

/**
 * Boolean expression tree for filters. An expression contains <CODE>AND|OR</CODE> operator and operands which are
 * filters or another expressions.
 *
 * Created by Hayk.Andriasyan on 5/23/2017.
 */
export class FilterTreeDto {

	/**
	 * Root node of the current tree, either <CODE>AND</CODE> or <CODE>OR</CODE> operator.
	 */
	public rootOperator: FilterTreeOperatorDto;

	public static fromJson(filterJson: string): FilterTreeDto {
		return FilterTreeDtoFactory.fromJson(filterJson);
	}

	public toJson(): string {
		return JSON.stringify(this);
	}
}
