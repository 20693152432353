import {FilterDto} from './filter-dto';

/**
 * Base class for category filter DTOs.
 *
 * Created by Hayk.Andriasyan on 5/23/2017.
 */
export abstract class AbstractCategoryFilterDto extends FilterDto {

	/**
	 * Category item holder id on which this filter applied on.
	 */
	public readonly onCategoryItemId: number|string;

	public constructor(type: string, filterType: string, onCategoryItemId: number|string) {
		super(type, filterType);
		this.onCategoryItemId = onCategoryItemId;
	}
}
