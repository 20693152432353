import {FilterTreeOperatorDto} from '../dto/filter-tree-operator-dto';
import {FilterDto} from '../dto/filter-dto';

/**
 * Created by Hayk.Andriasyan on 5/31/2017.
 */
export class BooleanExpression {
	operator: FilterTreeOperatorDto = null;

	constructor(protected _currentFilters: Array<FilterDto>) {
	}

	addFilter(filter: FilterDto): void {
		this.operator !== null ? this.operator.children.push(filter) : this._currentFilters.push(filter);
	}
}
