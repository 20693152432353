import {AbstractFilterElementDto} from './abstract-filter-element-dto';

/**
 * Base type for filters.
 *
 * Created by Hayk.Andriasyan on 5/23/2017.
 */
export class FilterDto extends AbstractFilterElementDto {
	/**
	 * Filter type e.g. 'is', 'in', 'like', ...
	 */
	public readonly filterType: string;

	/**
	 * Each filter may be excluded which means that the filter selects values sets which match
	 * <CODE>NOT(<FILTER_CONDITION>)</CODE>
	 *
	 * Boolean flag indicating that this filters matches value sets excluded from the condition.
	 */
	public excluded: boolean;

	/**
	 * Indicates if filter is visible for user.
	 */
	public isHiddenForUser: boolean;

	public constructor(type: string, filterType: string) {
		super(type);
		this.filterType = filterType;
		this.excluded = false;
		this.isHiddenForUser = false;
	}
}
