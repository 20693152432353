import {AbstractFilterElementDto} from './abstract-filter-element-dto';

/**
 * Common class for filter operators.
 *
 * Created by Hayk.Andriasyan on 5/23/2017.
 */
export class FilterTreeOperatorDto extends AbstractFilterElementDto {

	/**
	 * Filter tree operator type. 'or' or 'and'
	 */
	public readonly operator: string;
	/**
	 * Collection of filters/operators combined using the current operator.
	 */
	public children: Array<AbstractFilterElementDto>;

	public constructor(type: string, operator: string) {
		super(type);
		this.operator = operator;
		this.children = [];
	}
}
