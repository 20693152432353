/**
 * Options for Filter component
 */
export class Options {


	/**
	 * @param isSingleFilterMode use as a single filter not filter tree(with ANDs , ORs)
	 * @param hideItemsSelect if {@code true}  category/measure selection combo will be hidden.
	 */
	constructor(public isSingleFilterMode: boolean, public hideItemsSelect: boolean) {
	}
}
