import {Observable} from 'rxjs/Observable';
import {Pipe, PipeTransform} from '@angular/core';
import {MessageService} from '@synisys/idm-message-language-service-client-js';

/**
 * Angular {@link PipeTransform} implementation that transforms a message key into an appropriate text. To accommodate
 * this behavior {@link MessageService} should be injected and used.
 * This pipe is "pure" for performance considerations.
 * @see MessageService
 */
@Pipe({
	name: 'message',
	pure: true
})
export class MessagePipe implements PipeTransform {

	/**
	 * Creates the pipe component.
	 * @param {!MessageService} messageService - An instance of {@link MessageService} should be injected.
	 */
	public constructor(private messageService: MessageService) {
	}

	/**
	 * The transformation function imposed by the {@link PipeTransform} interface.
	 * @param {string} key - Transformation subject: the message key.
	 * @returns {Observable<string>} Transformation object: the actual text corresponding to the specified key.
	 * @override
	 */
	transform(key: string): Observable<string> {
		key = key ? key.trim() : '';

		if (key.length === 0) {
			return Observable.from(['']);
		}

		return this.messageService.getMessage(key);
	}

}
