import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {AbstractCriterion} from './abstract-criterion';
import {FilterItem} from '../filter/filter-item';

export abstract class AbstractCriterionValues<T> extends AbstractCriterion {

	constructor(filterItem: FilterItem, isNegating?: boolean, private _values: Array<T> = []) {
		super(filterItem, isNegating);

		PreconditionCheck.notNullOrUndefined(_values);
	}

	public get values(): Array<T> {
		return this.getValues();
	}

	public set values(values: Array<T>) {
		this.setValues(values);
	}

	protected getValues(): Array<T> {
		return this._values;
	}

	protected setValues(values: Array<T>) {
		this._values = values;

		this.validate();
	}

}
