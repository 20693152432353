import {BooleanExpression} from './boolean-expression';
import {FilterDto} from '../dto/filter-dto';
import {FilterTreeOperatorDto} from '../dto/filter-tree-operator-dto';
import {FilterDtoBuilder} from './filter-dto-builder';
import {CategoryItem} from './category-item';
import {MeasureItem} from './measure-item';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';

/**
 * Created by Hayk.Andriasyan on 5/31/2017.
 */
export class Or extends BooleanExpression {

	constructor(_currentFilters: Array<FilterDto>) {
		super(_currentFilters);
		this.operator = new FilterTreeOperatorDto(FilterDtoBuilder.OPERATOR, FilterDtoBuilder.OR_OPERATOR);
	}

	public orCategory(categoryItemDtoId: number): CategoryItem<Or> {
		PreconditionCheck.notNullOrUndefined(categoryItemDtoId);
		return new CategoryItem<Or>(this, categoryItemDtoId);
	}

	public orMeasure(measureItemDtoId: number): MeasureItem<Or> {
		PreconditionCheck.notNullOrUndefined(measureItemDtoId);
		return new MeasureItem<Or>(this, measureItemDtoId);
	}
}
