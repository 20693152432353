import {Observable} from 'rxjs/Rx';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {FilterItem} from './filter-item';

export class FilterGroup {

	constructor() {
		this._filterItems = [];
	}

	private _filterItems: Array<FilterItem>;

	public get filterItems(): Array<FilterItem> {
		return this._filterItems;
	}

	public set filterItems(filterItems: Array<FilterItem>) {
		this._filterItems = filterItems;
	}

	public validate(onlyThoseRequiringValidation: boolean = false): void {
		PreconditionCheck.notNullOrUndefined(onlyThoseRequiringValidation);

		this.filterItems.forEach((filterItem: FilterItem): void => {
			if (onlyThoseRequiringValidation === false || onlyThoseRequiringValidation === true && filterItem.requiresCriterionValidation()) {
				filterItem.validate();
			}
		});
	}

	public isValid(): Observable<Array<boolean>> {
		let itemValidationObservables: Array<Observable<boolean>> = [];

		itemValidationObservables = this.filterItems
			.map((filterItem: FilterItem): Observable<boolean> => {
				return filterItem.criterionValidator.isValid;
			});

		return Observable.combineLatest(itemValidationObservables);
	}

	public isTemporary(): boolean {
		return this.filterItems.every((filterItem: FilterItem): boolean => {
			return filterItem.isTemporary();
		});
	}

}
