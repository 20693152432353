import {AbstractCategoryFilterDto} from './abstract-category-filter-dto';

/**
 * Exact match to the current user. This filter used only for User category.
 *
 */
export class IsCurrentUserFilterDto extends AbstractCategoryFilterDto {

	public constructor(type: string, filterType: string, onCategoryItemId: number|string) {
		super(type, filterType, onCategoryItemId);
	}
}
