import {AbstractCategoryFilterDto} from './abstract-category-filter-dto';

/**
 * The filter matches the set of options. Selects values which contain the category identifiers held in
 * {@link #values}
 *
 * Created by Hayk.Andriasyan on 5/23/2017.
 */
export class InFilterDto extends AbstractCategoryFilterDto {

	/**
	 * Collection of indices for selected options.
	 */
	public values: Array<number>;

	public constructor(type: string, filterType: string, onCategoryItemId: number|string) {
		super(type, filterType, onCategoryItemId);
		this.values = [];
	}
}
