import {FilterTreeOperatorDto} from '../dto/filter-tree-operator-dto';
import {FilterDto} from '../dto/filter-dto';
import {FilterTreeDto} from '../dto/filter-tree-dto';
import {CategoryItem} from './category-item';
import {MeasureItem} from './measure-item';
import {Undecided} from './undecided';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';

/**
 * FilterDtoBuilder class defines a DSL to build a filter tree for reports. Filter trees generated using instances of
 * this class contain two layers of boolean expressions.
 * <p>
 * Each call to <code>filterByCategory</code> or <code>filterByMeasure</code> methods creates a filter tree
 * node combined using a certain boolean operator (<code>AND</code> or <code>OR</code>).
 * <p>
 * The following code snippet demonstrates the basic usage of the class:
 *
 * <pre>
 *   let builder:FilterDtoBuilder = ...;
 *   // initialize builder here
 *
 *   builder.filterByMeasure('Committed').between(10000, 200000, 1)
 *                  .orCategory('DonorName').is('World Bank')
 *                    .orCategory('SectorId').not()
 *                      .in(1, 2, 3);
 *   builder.filterByMeasure('StartDate').between(new Date('2008-02-02'), new Date('2008-04-02'))
 *                    .orMeasure('EndDate').onOrBefore(new Date('2011-10-10'));
 *   builder.filterByMeasure('DonorName')
 *                    .contains('Asian Development Bank')
 *                    .orMeasure('PartnerName').contains('Asian Development Bank');
 *
 *   let filterTreeDto = f.build();
 *   // if the particular FilterDtoBuilder combines top-level groups using AND operator
 *   // the code above generates the following filter tree
 *   // (Committed_USD between 10000 and 20000 OR DonorName = 'World Bank' OR NOT(SectorId in (1, 2, 3)))
 *   // AND
 *   // (StartDate between '2008-02-02' and '2008-04-02' or EndDate <= '2011-10-10')
 *   // AND
 *   // (DonorName like '%Asian Development Bank%' OR PartnerName like '%Asian Development Bank%')
 * </pre>
 *
 * JSON format (pseudo ENBF):
 *
 * <pre>
 *
 * filtertree = {
 *               operator,
 *               children
 *              }
 *
 * operator = 'operator':'and' | 'operator':'or'
 *
 * children = 'children':[
 *                         (filter | filtertree)+
 *                       ]
 *
 * filter = {
 *            'type':'filter',
 *             filtertype,
 *             excluded?
 *          }
 *
 * filtertype = in | isCurrent | isLast | isNext |
 *              is |
 *              contains | beginsWith | endsWith |
 *              between | greaterThan | greaterThanOrEqual | lessThan | lessThanOrEqual |
 *              betweenDates | after | before | onOrBefore | onOrAfter
 *
 * period = 'year' | 'quarter' | 'month' | 'week' | 'day'
 *
 * excluded = 'excluded': (true | false)
 *
 * in = 'filterType':'in',
 *      'categoryId':categoryId,
 *      'values':[
 *                 number+
 *               ]
 *
 * isCurrent = 'filterType':'isCurrent',
 *             'categoryId':categoryId,
 *             'period':period
 *
 * isLast = 'filterType':'isLast',
 *          'categoryId':categoryId,
 *          'period':period
 *
 * isNext = 'filterType':'isNext',
 *          'categoryId':categoryId,
 *          'period':period
 *
 *
 * is = 'filterType':'is',
 *      ('measureId':measureId,
 *      'currencyId':currencyId?,
 *      'value':(number | date | string) ) |
 *      ('categoryId':categoryId,
 *       'value':date)
 *
 *
 * contains = 'filterType':'contains',
 *        'measureId':measureId,
 *        'value':string
 *
 * startsWith = 'filterType':'startsWith',
 *              'measureId':measureId
 *              'value':string
 *
 * endsWith = 'filterType':'endsWith',
 *              'measureId':measureId
 *              'value':string
 *
 * between = 'filterType':'between',
 *           'measureId':measureId,
 *           'currencyId':currencyId?,
 *           'valueFrom':number,
 *           'valueTo':number
 *
 * betweenDates = 'filterType':'betweenDates',
 *                ('measureId':measureId) | ('categoryId':categoryId),
 *                'valueFrom':date,
 *                'valueTo':date
 *
 * greaterThan = 'filterType':'greaterThan',
 *               'measureId':measureId,
 *               'currencyId':currencyId?,
 *               'value':number
 *
 * greaterThanOrEqual = 'filterType':'greaterOrEqualThan',
 *                      'measureId':measureId,
 *                      'currencyId':currencyId?,
 *                      'value':number
 *
 * lessThan = 'filterType':'lessThan',
 *            'measureId':measureId,
 *            'currencyId':currencyId?,
 *            'value':number
 *
 * lessThanOrEqual = 'filterType':'lessOrEqualThan',
 *                   'measureId':measureId,
 *                   'currencyId':currencyId?,
 *                   'value':number
 *
 * before = 'filterType':'before',
 *          ('measureId':measureId) | ('categoryId':categoryId),
 *          'value':date
 *
 * after = 'filterType':'after',
 *         ('measureId':measureId) | ('categoryId':categoryId),
 *         'value':date
 *
 * onOrBefore = 'filterType':'onOrBefore',
 *              ('measureId':measureId) | ('categoryId':categoryId),
 *              'value':date
 *
 * onOrAfter = 'filterType':'onOrAfter',
 *             ('measureId':measureId) | ('categoryId':categoryId),
 *             'value':date
 * </pre>
 * Example JSON:
 *
 * <pre>
 *{
 *	  'type':'operator',
 *    'operator': 'and',
 *    'children': [
 *        {
 *        	  'type':'operator',
 *            'operator': 'or',
 *            'children': [
 *                {
 *                    'type':'filter',
 *                    'filterType': 'between',
 *                    'onMeasureItemId': 'Commitment',
 *                    'currencyId':1,
 *                    'valueFrom': 10000,
 *                    'valueTo': 20000
 *                },
 *                {
 *                    'type':'filter',
 *                    'filterType': 'is',
 *                    'onMeasureItemId': 'donorname',
 *                    'value': 'World Bank',
 *                    'excluded': false
 *                },
 *                {
 *                    'type':'filter',
 *                    'onCategoryItemId': 'Sector',
 *                    'filterType': 'in',
 *                    'values': [
 *                        1,
 *                        3,
 *                        3
 *                    ],
 *                    'excluded': true
 *                }
 *            ]
 *        },
 *        {
 *            'type':'operator',
 *            'operator': 'or',
 *            'children': [
 *                {
 *                    'type':'filter',
 *                    'filterType': 'betweenDates',
 *                    'onMeasureItemId': 'YearId',
 *                    'valueFrom': '2008-02-02',
 *                    'valueTo': '2008-04-02'
 *                },
 *                {
 *                    'type':'filter',
 *                    'filterType': 'before',
 *                    'onMeasureItemId': 'QuarterId',
 *                    'valueTo': '2011-10-10'
 *                }
 *            ]
 *        },
 *        {
 *        	  'type':'operator',
 *            'operator': 'or',
 *            'children': [
 *                {
 *                    'type':'filter',
 *                    'filterType':'contains',
 *                    'onMeasureItemId':'donorname',
 *                    'value': 'Asian Development Bank'
 *                },
 *                {
 *                    'type':'filter',
 *                    'filterType':'contains',
 *                    'onMeasureItemId':'partnername',
 *                    'value': 'Asian Development Bank'
 *                }
 *            ]
 *        }
 *    ]
 *}
 * </pre>
 */
export class FilterDtoBuilder {

	public static readonly EXACT = 'exact';
	public static readonly LIKE = 'like';
	public static readonly ON_OR_AFTER = 'onOrAfter';
	public static readonly AFTER = 'after';
	public static readonly ON_OR_BEFORE = 'onOrBefore';
	public static readonly BEFORE = 'before';
	public static readonly CONTAINS = 'contains';
	public static readonly STARTS_WITH = 'startsWith';
	public static readonly ENDS_WITH = 'endsWith';
	public static readonly BETWEEN = 'between';
	public static readonly BETWEEN_DATES = 'betweenDates';
	public static readonly LESS_THAN = 'lessThan';
	public static readonly LESS_THAN_OR_EQUAL = 'lessThanOrEqual';
	public static readonly GREATER_THAN = 'greaterThan';
	public static readonly GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual';
	public static readonly SPECIFIED = 'specified';
	public static readonly UNSPECIFIED = 'unspecified';
	public static readonly IN = 'in';
	public static readonly IS_LAST = 'isLast';
	public static readonly IS_NEXT = 'isNext';
	public static readonly IS_CURRENT = 'isCurrent';
	public static readonly IS = 'is';
	public static readonly TOP = 'top';
	public static readonly OPERATOR = 'operator';
	public static readonly AND_OPERATOR = 'and';
	public static readonly FILTER = 'filter';
	public static readonly OR_OPERATOR = 'or';
	public static readonly IS_CURRENT_USER = 'isCurrentUser';

	private branchOperator: Array<FilterTreeOperatorDto> = [];
	private isRootAnd = true;
	private currentFilters: Array<FilterDto> = [];

	public static createAndFilterBuilder(): FilterDtoBuilder {
		const builder: FilterDtoBuilder = new FilterDtoBuilder();
		builder.isRootAnd = true;
		return builder;
	}

	public static createOrFilterBuilder(): FilterDtoBuilder {
		const builder: FilterDtoBuilder = new FilterDtoBuilder();
		builder.isRootAnd = false;
		return builder;
	}

	/**
	 * Combines specified filter trees into a logical {@code OR} and returns the combined tree. <br/>
	 * May throw {@code Error} if count of provided filter trees is 0.
	 */
	public static or(tree: FilterTreeDto, filters: Array<FilterDto>): FilterTreeDto;
	public static or(leftFilterTrees: FilterTreeDto[]): FilterTreeDto;
	public static or(tree: any, filters?: Array<FilterDto>): FilterTreeDto {

		return tree instanceof FilterTreeDto ?
			FilterDtoBuilder._createFilterTreeWithOperator(tree, filters, FilterDtoBuilder.OR_OPERATOR) :
			FilterDtoBuilder.combineOr(...tree);
	}

	/**
	 * Combines two filter trees into a logical {@code AND} and returns the combined tree.
	 *
	 * @return a new filter tree containing the logical {@code AND} of the given trees.
	 */
	public static and(tree: FilterTreeDto, filters: FilterTreeDto | Array<FilterDto>): FilterTreeDto {

		return filters instanceof FilterTreeDto ?
			FilterDtoBuilder.combineAnd(tree, filters) :
			FilterDtoBuilder._createFilterTreeWithOperator(tree, <Array<FilterDto>>filters, FilterDtoBuilder.AND_OPERATOR);
	}

	/**
	 * Makes a filter tree containing all the filters in given filter tree array combined using logical OR operator.
	 * May throw {@code Error} if count of provided filter trees is 0.
	 *
	 * @param forest filter trees to combine
	 * @return a newly created filter tree containing logical {@code OR} of all the filters in {@code forest}
	 */
	public static combineOr(...forest: FilterTreeDto[]): FilterTreeDto ;

	public static combineOr(forest: Array<FilterTreeDto>): FilterTreeDto ;

	public static combineOr(forest: any): FilterTreeDto {
		if (forest.length === 0) {
			throw new Error('At least one tree expected');
		}
		// check whether forest is rest type convert it into array
		if (arguments.length > 0 && !(forest instanceof Array)) {
			forest = Array.prototype.slice.call(arguments);
		}

		const newTree: FilterTreeDto = new FilterTreeDto();
		const newRoot: FilterTreeOperatorDto = new FilterTreeOperatorDto(FilterDtoBuilder.OPERATOR, FilterDtoBuilder.OR_OPERATOR);

		for (const tree of forest) {
			const root: FilterTreeOperatorDto = tree.rootOperator;

			if (FilterDtoBuilder.AND_OPERATOR === root.operator) {
				newRoot.children.push(FilterDtoBuilder._copyAnd(root));
			} else if (FilterDtoBuilder.OR_OPERATOR === root.operator) {
				FilterDtoBuilder._copyOrTo(newRoot, root);
			}
		}

		newTree.rootOperator = newRoot;
		return newTree;
	}

	/**
	 * Combines two filter trees into a logical and and returns the combined tree.
	 *
	 * @param one the first tree to combine.
	 * @param two the second one.
	 * @return a new filter tree containing the logical {@code AND} and returns the combined tree.
	 */
	public static combineAnd(one: FilterTreeDto, two: FilterTreeDto): FilterTreeDto {
		// case 1. one/two are AND: (of1 and of2 and (oo1) and (oo2)) and (tf1 and tf2 and (to1) and (to2)) <=>
		//                          (of1 and of2 and tf1 and tf2 and (oo1) and (oo2) and (to1) and (to2)
		// case 2. one - AND, two - OR: (of1 and of2 and (oo1) and (oo2)) and (tf1 or tf2 or (to1) and (to2)) <=>
		//                              of1 and of2 and (oo1) and (oo2) and (tf1 or tf2 or (to1) and (to2))
		// case 3: one - OR, two - AND: see above, replace '^o' with 't'
		// case 4: one/two are OR: (of1 or of2 or (oo1) or (oo2)) and (tf1 or tf2 of (to1) or (to2)) <=>
		//                         () and (of1 or of2 or (oo1) or (oo2)) and (tf1 or tf2 of (to1) or (to2))

		if (one == null || typeof one === 'undefined') {
			return two;
		}

		if (two == null || typeof two === 'undefined') {
			return one;
		}

		let oneRoot: FilterTreeOperatorDto = one.rootOperator;
		let twoRoot: FilterTreeOperatorDto = two.rootOperator;
		if ((FilterDtoBuilder.OR_OPERATOR === oneRoot.operator) && (FilterDtoBuilder.AND_OPERATOR === twoRoot.operator)) {
			oneRoot = twoRoot;
			twoRoot = one.rootOperator;
		}

		let newRoot: FilterTreeOperatorDto = null;
		if (FilterDtoBuilder.AND_OPERATOR === oneRoot.operator) {
			newRoot = FilterDtoBuilder._copyAnd(oneRoot);
			if (FilterDtoBuilder.AND_OPERATOR === twoRoot.operator) {
				FilterDtoBuilder._copyFiltersTo(newRoot, twoRoot);

				for (const or of twoRoot.children) {
					newRoot.children.push(FilterDtoBuilder._copyOr(or as FilterTreeOperatorDto));
				}

			} else {
				newRoot.children.push(FilterDtoBuilder._copyOr(twoRoot));
			}
		} else { // both are OR in regard the swapping above
			newRoot = new FilterTreeOperatorDto(FilterDtoBuilder.OPERATOR, FilterDtoBuilder.AND_OPERATOR);
			newRoot.children.push(FilterDtoBuilder._copyOr(oneRoot));
			newRoot.children.push(FilterDtoBuilder._copyOr(twoRoot));
		}

		const newTree: FilterTreeDto = new FilterTreeDto();
		newTree.rootOperator = newRoot;
		return newTree;
	}

	private static _createFilterTreeWithOperator(tree: FilterTreeDto, filters: Array<FilterDto>, operator: string): FilterTreeDto {
		const newTree: FilterTreeDto = new FilterTreeDto();
		const newRoot: FilterTreeOperatorDto = new FilterTreeOperatorDto(FilterDtoBuilder.OPERATOR, operator);
		newTree.rootOperator = newRoot;

		if (tree !== null) {
			const oldRoot: FilterTreeOperatorDto = tree.rootOperator;

			if (operator === oldRoot.operator) {
				newRoot.children = newRoot.children.concat(oldRoot.children);
			} else {
				newRoot.children.push(oldRoot);
			}
		}

		newRoot.children = newRoot.children.concat(filters);
		return newTree;
	}

	/**
	 * Creates and returns a deep copy of the given operator. Filters throughout the tree are not cloned, the same
	 * instances are put to the new tree.
	 *
	 * @param op operator to copy.
	 * @return a deep copy of the operator.
	 */
	private static _copyOr(op: FilterTreeOperatorDto): FilterTreeOperatorDto {
		const newOp: FilterTreeOperatorDto = new FilterTreeOperatorDto(FilterDtoBuilder.OPERATOR, FilterDtoBuilder.OR_OPERATOR);
		FilterDtoBuilder._copyOrTo(newOp, op);
		return newOp;
	}

	private static _copyOrTo(newOp: FilterTreeOperatorDto, op: FilterTreeOperatorDto): void {
		FilterDtoBuilder._copyFiltersTo(newOp, op);

		if (op.children !== null) {
			for (const andOp of op.children) {
				if (FilterDtoBuilder.OPERATOR === andOp.type) {
					newOp.children.push(FilterDtoBuilder._copyAnd(andOp as FilterTreeOperatorDto));
				}
			}
		}
	}

	/**
	 * Creates and returns a deep copy of the given operator. Filters throughout the tree are not cloned, the same
	 * instances are put to the new tree.
	 *
	 * @param op operator to copy.
	 * @return a deep copy of the operator.
	 */
	private static _copyAnd(op: FilterTreeOperatorDto): FilterTreeOperatorDto {
		const newOp: FilterTreeOperatorDto = new FilterTreeOperatorDto(FilterDtoBuilder.OPERATOR, FilterDtoBuilder.AND_OPERATOR);
		FilterDtoBuilder._copyAndTo(newOp, op);
		return newOp;
	}

	private static _copyAndTo(newOp: FilterTreeOperatorDto, op: FilterTreeOperatorDto): void {
		FilterDtoBuilder._copyFiltersTo(newOp, op);
		if (op.children != null) {
			for (const orOp of op.children) {
				if (FilterDtoBuilder.OPERATOR === orOp.type) {
					newOp.children.push(FilterDtoBuilder._copyOr(orOp as FilterTreeOperatorDto));
				}
			}
		}
	}

	private static _copyFiltersTo(newOp: FilterTreeOperatorDto, op: FilterTreeOperatorDto): void {
		PreconditionCheck.notNullOrUndefined(newOp);
		PreconditionCheck.notNullOrUndefined(op);
		if (op.children !== null) { // filters are immutable (at the moment of writing this code) so just take them
			for (const f of op.children) {
				if (FilterDtoBuilder.FILTER === f.type) {
					newOp.children.push(f);
				}
			}
		}
	}

	/**
	 * Measure filtering facilities. Provides condition checking for measures.
	 *
	 * @param measureItemId   Measure item id.
	 */
	public filterByMeasure(measureItemId: number|string): MeasureItem<Undecided> {
		PreconditionCheck.notNullOrUndefined(measureItemId);
		return new MeasureItem<Undecided>(new Undecided(this.currentFilters, this.branchOperator), measureItemId);
	}

	/**
	 * Category filtering facilities. Provides condition checking for categories.
	 *
	 * @param categoryItemId  Category item id.
	 */
	public filterByCategory(categoryItemId: number|string): CategoryItem<Undecided> {
		PreconditionCheck.notNullOrUndefined(categoryItemId);
		return new CategoryItem<Undecided>(new Undecided(this.currentFilters, this.branchOperator), categoryItemId);
	}

	/**
	 * Builds a filter tree.
	 *
	 * @return the filter tree built.
	 */
	public build(): FilterTreeDto {
		const tree: FilterTreeDto = new FilterTreeDto();
		const root: FilterTreeOperatorDto = new FilterTreeOperatorDto(FilterDtoBuilder.OPERATOR,
			this.isRootAnd ? FilterDtoBuilder.AND_OPERATOR : FilterDtoBuilder.OR_OPERATOR);

		root.children = root.children.concat(this.currentFilters);

		for (const operator of this.branchOperator) {
			if (root.operator === operator.operator) {
				root.children = root.children.concat(operator.children);
			} else {
				root.children.push(operator);
			}
		}

		tree.rootOperator = root;

		return tree;
	}

}
