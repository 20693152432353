import {AbstractMeasureFilterDto} from './abstract-measure-filter-dto';
import {FilterDtoBuilder} from '../builder/filter-dto-builder';

/**
 * Created by Hayk.Andriasyan on 6/1/2017.
 */

export class ExactFilterDto extends AbstractMeasureFilterDto {

	public static readonly EXACT: string = 'exact';

	/**
	 * The value used in the pattern
	 */
	public readonly value: string;

	public constructor(onMeasureItemId: number|string, value: string, currencyId?: number, byCategoryItemId?: number|string) {
		super(FilterDtoBuilder.FILTER, ExactFilterDto.EXACT, onMeasureItemId, byCategoryItemId || null, currencyId || null);
		this.value = value;
	}
}
