import {AbstractCategoryFilterDto} from './abstract-category-filter-dto';

/**
 * Filter to check time periods for days, weeks, months, quarters and years.
 * Currently three time periods are supported: last, current, next.
 *
 * Created by Hayk.Andriasyan on 5/23/2017.
 */
export class TimePeriodFilterDto extends AbstractCategoryFilterDto {

	public readonly value: number;

	public constructor(type: string, filterType: string, onCategoryItemId: number|string, value: number) {
		super(type, filterType, onCategoryItemId);
		this.value = value;
	}
}
