import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {AbstractCriterion} from './abstract-criterion';
import {RangeBounding} from './range-bounding';
import {FilterItem} from '../filter/filter-item';

/**
 * Above all commons facilitates basic features of a criterion denoting a range with minimal and maximal values.
 */
export abstract class AbstractCriterionRange<T> extends AbstractCriterion {

	/**
	 * Constructs the range with initial parameters.
	 * @param {FilterItem} filterItem - The item hosting this particular criteria.
	 * @param {boolean} isNegating - Whether the associated filtering method is negating.
	 * @param {!boolean} _isIncluding - Whether the range boundaries are included.
	 * @param {!RangeBounding} _bounding - Whether the range is bounded and if yes then from which side.
	 * @param {!T} _valueFrom - The minimal value.
	 * @param {!T} _valueTo - The maximal value.
	 * @see FilterType
	 * @see FilterTypeNegations
	 */
	constructor(filterItem: FilterItem, isNegating?: boolean, private _isIncluding: boolean = false, private _bounding: RangeBounding = null, private _valueFrom: T = null, private _valueTo: T = null) {
		super(filterItem, isNegating);

		PreconditionCheck.notUndefined(_isIncluding);
		PreconditionCheck.notUndefined(_bounding);
		PreconditionCheck.notUndefined(_valueFrom);
		PreconditionCheck.notUndefined(_valueTo);
	}

	/**
	 * Check whether the range values are included in the range.
	 * @returns {boolean} True if they are, false otherwise.
	 */
	public get isIncluding(): boolean {
		return this._isIncluding;
	}

	/**
	 * Specify whether the range values are included in the range.
	 * @param {boolean} isIncluding - True if they are, false otherwise.
	 */
	public set isIncluding(isIncluding: boolean) {
		this._isIncluding = isIncluding;
	}

	/**
	 * Check if the lower or upper values of the range are fixed - the range is bounded - or not in which case the value
	 * is not specified (null).
	 * @returns {RangeBounding} The bounding type if any.
	 */
	public get bounding(): RangeBounding {
		return this._bounding;
	}

	/**
	 * Specify if the lower or upper values of the range are fixed - the range is bounded - or not in which case the value
	 * is not specified (null).
	 * @param {RangeBounding} bounding - The bounding type if any.
	 */
	public set bounding(bounding: RangeBounding) {
		this._bounding = bounding;
	}

	/**
	 * Accessor to get the property.
	 * @returns {T} The lower value.
	 */
	public get valueFrom(): T {
		return this.getValueFrom();
	}

	/**
	 * Accessor to set the property.
	 * @param {T} valueFrom - The lower value.
	 */
	public set valueFrom(valueFrom: T) {
		this.setValueFrom(valueFrom);
	}

	/**
	 * Accessor to get the property.
	 * @returns {T} The upper value.
	 */
	public get valueTo(): T {
		return this.getValueTo();
	}

	/**
	 * Accessor to set the property.
	 * @param {T} valueTo - The upper value.
	 */
	public set valueTo(valueTo: T) {
		this.setValueTo(valueTo);
	}

	/**
	 * Getter method (not accessor) to be overridden in descendants if needed.
	 * @returns {T} The lower value.
	 */
	protected getValueFrom(): T {
		return this._valueFrom;
	}

	/**
	 * Setter method (not accessor) to be overridden in descendants if needed. Each time the value is changed criterion
	 * is validated.
	 * @param {T} valueFrom - The lower value.
	 */
	protected setValueFrom(valueFrom: T) {
		this._valueFrom = valueFrom;

		this.validate();
	}

	/**
	 * Getter method (not accessor) to be overridden in descendants if needed.
	 * @returns {T} The upper value.
	 */
	protected getValueTo(): T {
		return this._valueTo;
	}

	/**
	 * Setter method (not accessor) to be overridden in descendants if needed. Each time the value is changed criterion
	 * is validated.
	 * @param {T} valueTo - The upper value.
	 */
	protected setValueTo(valueTo: T) {
		this._valueTo = valueTo;

		this.validate();
	}

}
