import {CriterionVisitor} from './criterion-visitor';
import {AbstractCriterion} from '../criterion/abstract-criterion';
import {IndexedValue, PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {MeasureCriterionMoneyRange} from '../criterion/measure-criterion-money-range';
import {MeasureCriterionNumberRange} from '../criterion/measure-criterion-number-range';
import {CriterionNumberRange} from '../criterion/criterion-number-range';
import {AbstractCriterionRange} from '../criterion/abstract-criterion-range';
import {MeasureCriterionMoney} from '../criterion/measure-criterion-money';
import {MeasureCriterionNumber} from '../criterion/measure-criterion-number';
import {CategoryCriterionNumber} from '../criterion/category-criterion-number';
import {CriterionNumber} from '../criterion/criterion-number';
import {AbstractCriterionValue} from '../criterion/abstract-criterion-value';
import {AbstractCriterionValues} from '../criterion/abstract-criterion-values';
import {CriterionDate} from '../criterion/criterion-date';
import {CriterionDateRange} from '../criterion/criterion-date-range';
import {CriterionNumbers} from '../criterion/criterion-numbers';
import {CriterionString} from '../criterion/criterion-string';
import {RangeBounding} from '../criterion/range-bounding';
import {CriterionHelper} from '../helper/criterion-helper';
import {FilterType} from '../filter/type/filter-type';

import 'rxjs/add/operator/debounceTime';
import {Subscription} from 'rxjs/Subscription';
import {OnDestroy} from '@angular/core';

export class CriterionValidator implements CriterionVisitor, OnDestroy {

	private readonly MESSAGE_KEY_SELECT_VALUE: string = 'reporting.controls.filter.validation.selectValue';
	private readonly MESSAGE_KEY_SELECT_VALUES: string = 'reporting.controls.filter.validation.selectValues';
	private readonly MESSAGE_KEY_SPECIFY_VALUE: string = 'reporting.controls.filter.validation.specifyValue';
	private readonly MESSAGE_KEY_SELECT_MEASURE: string = 'reporting.controls.filter.validation.selectMeasure';
	private readonly MESSAGE_KEY_SPECIFY_COUNT: string = 'reporting.controls.filter.validation.specifyCount';
	private readonly MESSAGE_KEY_RANGE_NUMBER: string = 'reporting.controls.filter.validation.rangeNumber';
	private readonly MESSAGE_KEY_RANGE_DATE: string = 'reporting.controls.filter.validation.rangeDate';
	private readonly MESSAGE_KEY_NEGATIVE: string = 'reporting.controls.filter.validation.negative';
	private readonly MESSAGE_KEY_NEGATIVE_OR_ZERO: string = 'reporting.controls.filter.validation.negativeOrZero';
	private visitSubject: Subject<AbstractCriterion>;
	private visitSubjectSubscription: Subscription;

	constructor(private delay: number = 500) {
		this._isValid = new Subject<boolean>();
		this._validity = true;
		this.clearValidations();
		this.visitSubject = new Subject<AbstractCriterion>();

		this.subscribeVisitSubject(delay);
	}

	private _isValid: Subject<boolean>;

	public get isValid(): Observable<boolean> {
		return this._isValid;
	}

	private _validity: boolean;

	public get validity(): boolean {
		return this._validity;
	}

	public set validity(isValid: boolean) {
		PreconditionCheck.notNullOrUndefined(isValid);

		this._isValid.next(isValid);
		this._validity = isValid;
	}

	private _validations: IndexedValue<string>;

	public get validations(): IndexedValue<string> {
		return this._validations;
	}

	public clearValidations(): void {
		this._validations = new IndexedValue<string>();
	}

	public visit(criterion: CategoryCriterionNumber): void;

	public visit(criterion: MeasureCriterionMoney): void;

	public visit(criterion: MeasureCriterionMoneyRange): void;

	public visit(criterion: MeasureCriterionNumber): void;

	public visit(criterion: MeasureCriterionNumberRange): void;

	public visit(criterion: CriterionDate): void;

	public visit(criterion: CriterionDateRange): void;

	public visit(criterion: CriterionNumber): void;

	public visit(criterion: CriterionNumberRange): void;

	public visit(criterion: CriterionNumbers): void;

	public visit(criterion: CriterionString): void;

	public visit(criterion: AbstractCriterion): void {
		PreconditionCheck.notNullOrUndefined(criterion);

		this.visitSubject.next(criterion);
	}

	private subscribeVisitSubject(delay: number) {
		PreconditionCheck.notNullOrUndefined(delay);

		this.visitSubjectSubscription = this.visitSubject
			.debounceTime(delay)
			.subscribe((criterion: AbstractCriterion): void => {
				const isValid = this._visit(criterion);

				this._isValid.next(isValid);
				this._validity = isValid;
			});
	}

	private _visit(criterion: AbstractCriterion): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);

		let isValid = false;
		const validations = new IndexedValue<string>();

		// CAUTION!!! Order matters!
		if (criterion instanceof CategoryCriterionNumber) {
			isValid = this.visitCategoryCriterionNumber(criterion, validations);
		} else if (criterion instanceof MeasureCriterionMoney) {
			isValid = this.visitMeasureCriterionMoney(criterion, validations);
		} else if (criterion instanceof MeasureCriterionMoneyRange) {
			isValid = this.visitMeasureCriterionMoneyRange(criterion, validations);
		} else if (criterion instanceof MeasureCriterionNumber) {
			isValid = this.visitMeasureCriterionNumber(criterion, validations);
		} else if (criterion instanceof MeasureCriterionNumberRange) {
			isValid = this.visitMeasureCriterionNumberRange(criterion, validations);
		} else if (criterion instanceof CriterionDate) {
			isValid = this.visitCriterionDate(criterion, validations);
		} else if (criterion instanceof CriterionDateRange) {
			isValid = this.visitCriterionDateRange(criterion, validations);
		} else if (criterion instanceof CriterionNumber) {
			isValid = this.visitCriterionNumber(criterion, validations);
		} else if (criterion instanceof CriterionNumberRange) {
			isValid = this.visitCriterionNumberRange(criterion, validations);
		} else if (criterion instanceof CriterionNumbers) {
			isValid = this.visitCriterionNumbers(criterion, validations);
		} else if (criterion instanceof CriterionString) {
			isValid = this.visitCriterionString(criterion, validations);
		} else {
			isValid = false;
			console.error('Cannot validate: specified criterion doesn\'t match to any known type.');
		}

		this._validations = validations;

		return isValid;
	}

	private visitCategoryCriterionNumber(criterion: CategoryCriterionNumber, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		const filterType = criterion.filterItem.filterType;

		let isValid1 = this.visitCriterionNumber(criterion, validations);
		const isValid2 = this.visitByMeasureItemId(criterion, validations);

		if (!isValid1) {
			validations['value'] = this.MESSAGE_KEY_SPECIFY_COUNT;
		}

		if (isValid1 && filterType === FilterType.TOP && criterion.value <= 0) {
			isValid1 = false;
			validations['value'] = this.MESSAGE_KEY_NEGATIVE_OR_ZERO;
			// console.warn("Specified 'value' should be greater than zero.");
		}

		return isValid1 && isValid2;
	}

	private visitMeasureCriterionMoney(criterion: MeasureCriterionMoney, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid1 = this.visitMeasureCriterionNumber(criterion, validations);
		const isValid2 = this.visitCurrencyId(criterion, validations);

		if (isValid1 && criterion.value < 0) {
			isValid1 = false;
			validations['value'] = this.MESSAGE_KEY_NEGATIVE;
			// console.warn("Specified 'value' should be positive.");
		}

		return isValid1 && isValid2;
	}

	private visitMeasureCriterionMoneyRange(criterion: MeasureCriterionMoneyRange, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		const isValid1 = this.visitMeasureCriterionNumberRange(criterion, validations);
		const isValid2 = this.visitCurrencyId(criterion, validations);
		let isValid3 = true;

		if (!validations['valueFrom'] && criterion.valueFrom && criterion.valueFrom < 0) {
			isValid3 = false;
			validations['valueFrom'] = this.MESSAGE_KEY_NEGATIVE;
			// console.warn("Specified 'valueFrom' should be positive.");
		}

		if (!validations['valueTo'] && criterion.valueTo && criterion.valueTo < 0) {
			isValid3 = false;
			validations['valueTo'] = this.MESSAGE_KEY_NEGATIVE;
			// console.warn("Specified 'valueTo' should be positive.");
		}

		return isValid1 && isValid2 && isValid3;
	}

	private visitMeasureCriterionNumber(criterion: MeasureCriterionNumber, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		const isValid1 = this.visitCriterionNumber(criterion, validations);
		const isValid2 = this.visitByCategoryItemId(criterion, validations);

		return isValid1 && isValid2;
	}

	private visitMeasureCriterionNumberRange(criterion: MeasureCriterionNumberRange, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		const isValid1 = this.visitCriterionNumberRange(criterion, validations);
		const isValid2 = this.visitByCategoryItemId(criterion, validations);

		return isValid1 && isValid2;
	}

	private visitCriterionDate(criterion: CriterionDate, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = this.visitAbstractCriterionValue(criterion, validations);

		if (!CriterionHelper.isValidDate(criterion.value)) {
			isValid = false;
			validations['value'] = this.MESSAGE_KEY_SPECIFY_VALUE;
			// console.warn("Specified 'value' should be a date.");
		}

		return isValid;
	}

	private visitCriterionDateRange(criterion: CriterionDateRange, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = this.visitAbstractCriterionRange(criterion, validations);

		if ((criterion.bounding == null || criterion.bounding === RangeBounding.LOWER) && !CriterionHelper.isValidDate(criterion.valueFrom)) {
			isValid = false;
			validations['valueFrom'] = this.MESSAGE_KEY_SPECIFY_VALUE;
			// console.warn("Specified 'valueFrom' should be a date.");
		}

		if ((criterion.bounding == null || criterion.bounding === RangeBounding.UPPER) && !CriterionHelper.isValidDate(criterion.valueTo)) {
			isValid = false;
			validations['valueTo'] = this.MESSAGE_KEY_SPECIFY_VALUE;
			// console.warn("Specified 'valueTo' should be a date.");
		}

		if (criterion.bounding == null && CriterionHelper.isValidDate(criterion.valueFrom) && CriterionHelper.isValidDate(criterion.valueTo) &&
			criterion.valueFrom.getTime() >= criterion.valueTo.getTime()) {
			isValid = false;
			validations['valueFrom'] = this.MESSAGE_KEY_RANGE_DATE;
			// console.warn("Specified 'valueFrom' is greater than or equals 'valueTo'.");
		}

		return isValid;
	}

	private visitCriterionNumber(criterion: CriterionNumber, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		const filterType = criterion.filterItem.filterType;

		let isValid = this.visitAbstractCriterionValue(criterion, validations);

		if (!Number.isFinite(criterion.value)) {
			isValid = false;
			validations['value'] = this.MESSAGE_KEY_SPECIFY_VALUE;
			// console.warn("Specified 'value' should be a number.");
		} else if ((filterType === FilterType.PREVIOUS || filterType === FilterType.NEXT) && criterion.value <= 0) {
			isValid = false;
			validations['value'] = this.MESSAGE_KEY_NEGATIVE_OR_ZERO;
			// console.warn("Specified 'value' should be greater than zero.");
		}

		return isValid;
	}

	private visitCriterionNumberRange(criterion: CriterionNumberRange, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = this.visitAbstractCriterionRange(criterion, validations);

		if ((criterion.bounding == null || criterion.bounding === RangeBounding.LOWER) && !Number.isFinite(criterion.valueFrom)) {
			isValid = false;
			validations['valueFrom'] = this.MESSAGE_KEY_SPECIFY_VALUE;
			// console.warn("Specified 'valueFrom' should be a number.");
		}

		if ((criterion.bounding == null || criterion.bounding === RangeBounding.UPPER) && !Number.isFinite(criterion.valueTo)) {
			isValid = false;
			validations['valueTo'] = this.MESSAGE_KEY_SPECIFY_VALUE;
			// console.warn("Specified 'valueTo' should be a number.");
		}

		if (criterion.bounding == null && Number.isFinite(criterion.valueFrom) && Number.isFinite(criterion.valueTo) && criterion.valueFrom >= criterion.valueTo) {
			isValid = false;
			validations['valueFrom'] = this.MESSAGE_KEY_RANGE_NUMBER;
			// console.warn("Specified 'valueFrom' is greater than or equals 'valueTo'.");
		}

		return isValid;
	}

	private visitCriterionNumbers(criterion: CriterionNumbers, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = this.visitAbstractCriterionValues(criterion, validations);

		if (isValid) {
			const anyInvalid = criterion.values.some((value: any): boolean => {
				return value !== null && !Number.isFinite(value);
			});

			if (anyInvalid) {
				isValid = false;
				validations['values'] = this.MESSAGE_KEY_SELECT_VALUES;
				// console.warn("Specified 'values' should be numbers.");
			}
		}

		return isValid;
	}

	private visitCriterionString(criterion: CriterionString, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = this.visitAbstractCriterionValue(criterion, validations);

		if (typeof criterion.value !== 'string' || criterion.value.length === 0) {
			isValid = false;
			validations['value'] = this.MESSAGE_KEY_SPECIFY_VALUE;
			// console.warn("Specified 'value' should be a non-empty string.");
		}

		return isValid;
	}

	private visitAbstractCriterionRange(criterion: AbstractCriterionRange<any>, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = true;

		if ((criterion.bounding == null || criterion.bounding === RangeBounding.LOWER) && (criterion.valueFrom === null || criterion.valueFrom === undefined)) {
			isValid = false;
			validations['valueFrom'] = this.MESSAGE_KEY_SPECIFY_VALUE;
			// console.warn("The 'valueFrom' should be specified.");
		}

		if ((criterion.bounding == null || criterion.bounding === RangeBounding.UPPER) && (criterion.valueTo === null || criterion.valueTo === undefined)) {
			isValid = false;
			validations['valueTo'] = this.MESSAGE_KEY_SPECIFY_VALUE;
			// console.warn("The 'valueTo' should be specified.");
		}

		return isValid;
	}

	private visitAbstractCriterionValue(criterion: AbstractCriterionValue<any>, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = true;

		if (criterion.value === null || criterion.value === undefined) {
			isValid = false;
			validations['value'] = this.MESSAGE_KEY_SPECIFY_VALUE;
			// console.warn("The 'value' should be specified.");
		}

		return isValid;
	}

	private visitAbstractCriterionValues(criterion: AbstractCriterionValues<any>, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = true;

		if (!Array.isArray(criterion.values) || criterion.values.length === 0) {
			isValid = false;
			validations['values'] = this.MESSAGE_KEY_SELECT_VALUES;
			// console.warn("The 'values' should be a non-empty array.");
		}

		return isValid;
	}

	private visitByMeasureItemId(criterion: CategoryCriterionNumber, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = true;

		if (!(typeof criterion.byMeasureId === 'string' || Number.isInteger(<number> criterion.byMeasureId))) {
			isValid = false;
			validations['byMeasureId'] = this.MESSAGE_KEY_SELECT_MEASURE;
			// console.warn("Specified 'byMeasureId' should be an integer or string.");
		}

		return isValid;
	}

	private visitByCategoryItemId(criterion: MeasureCriterionNumber | MeasureCriterionNumberRange, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = true;

		if (criterion.byCategoryId !== null && !(typeof criterion.byCategoryId === 'string' || Number.isInteger(<number>criterion.byCategoryId))) {
			isValid = false;
			validations['byCategoryId'] = this.MESSAGE_KEY_SELECT_VALUE;
			// console.warn("Specified 'byCategoryId' should be an integer or string.");
		}

		return isValid;
	}

	private visitCurrencyId(criterion: MeasureCriterionMoney | MeasureCriterionMoneyRange, validations: IndexedValue<string>): boolean {
		PreconditionCheck.notNullOrUndefined(criterion);
		PreconditionCheck.notNullOrUndefined(validations);

		let isValid = true;

		if (!Number.isInteger(criterion.currencyId)) {
			isValid = false;
			validations['currencyId'] = this.MESSAGE_KEY_SELECT_VALUE;
			// console.warn("Specified 'currencyId' should be an integer.");
		}

		return isValid;
	}

	ngOnDestroy(): void {
		if (this.visitSubjectSubscription) {
			this.visitSubjectSubscription.unsubscribe();
		}
	}

}
