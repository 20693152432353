import {FilterTreeDto} from './filter-tree-dto';
import {FilterDtoBuilder} from '../builder/filter-dto-builder';
import {FilterTreeOperatorDto} from './filter-tree-operator-dto';
import {AbstractFilterElementDto} from './abstract-filter-element-dto';
import {FilterDto} from './filter-dto';
import {InFilterDto} from './in-filter-dto';
import {TimePeriodFilterDto} from './time-period-filter-dto';
import {IsFilterDto} from './is-filter-dto';
import {ExactFilterDto} from './exact-filter-dto';
import {ContainsFilterDto} from './contains-filter-dto';
import {StartsWithFilterDto} from './starts-with-filter-dto';
import {EndsWithFilterDto} from './ends-with-filter-dto';
import {CategoryRangeFilterDto} from './category-range-filter-dto';
import {MeasureRangeFilterDto} from './measure-range-filter-dto';
import {TopFilterDto} from './top-filter-dto';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {IsCurrentUserFilterDto} from './is-current-user-filter-dto';

/**
 * Get FilterTreeDto from json file
 *
 * Created by Hayk.Andriasyan on 8/18/2017.
 */
export class FilterTreeDtoFactory {
	/**
	 * Key is filter type, value is filter's type creator method
	 */
	private static readonly filterTypeDelegate = {
		'in': FilterTreeDtoFactory.inFilter,
		'specified': FilterTreeDtoFactory.inFilter,
		'unspecified': FilterTreeDtoFactory.inFilter,
		'isCurrent': FilterTreeDtoFactory.timePeriodFilter,
		'isCurrentUser': FilterTreeDtoFactory.isCurrentUser,
		'isLast': FilterTreeDtoFactory.timePeriodFilter,
		'isNext': FilterTreeDtoFactory.timePeriodFilter,
		'is': FilterTreeDtoFactory.is,
		'exact': FilterTreeDtoFactory.is,
		'contains': FilterTreeDtoFactory.contains,
		'startsWith': FilterTreeDtoFactory.startsWith,
		'endsWith': FilterTreeDtoFactory.endsWith,
		'between': FilterTreeDtoFactory.between,
		'betweenDates': FilterTreeDtoFactory.between,
		'greaterThan': FilterTreeDtoFactory.rangeFilter,
		'greaterThanOrEqual': FilterTreeDtoFactory.rangeFilter,
		'lessThan': FilterTreeDtoFactory.rangeFilter,
		'lessThanOrEqual': FilterTreeDtoFactory.rangeFilter,
		'before': FilterTreeDtoFactory.dateRangeFilter,
		'onOrBefore': FilterTreeDtoFactory.dateRangeFilter,
		'after': FilterTreeDtoFactory.dateRangeFilter,
		'onOrAfter': FilterTreeDtoFactory.dateRangeFilter,
		'top': FilterTreeDtoFactory.top,
	};

	public static fromJson(filterJson: string): FilterTreeDto {
		PreconditionCheck.notNull(filterJson);
		const filterObject = JSON.parse(filterJson);
		let tree: FilterTreeDto;

		if (filterObject.rootOperator.children.length === 0) {
			tree = FilterDtoBuilder.createAndFilterBuilder().build();
		} else {
			tree = new FilterTreeDto();
			tree.rootOperator = FilterTreeDtoFactory.traverseFilterTreeJson(filterObject.rootOperator) as FilterTreeOperatorDto;
		}

		return tree;
	}

	private static traverseFilterTreeJson(filterObject: any): AbstractFilterElementDto {
		let treeElement: AbstractFilterElementDto;

		if (filterObject.type === FilterDtoBuilder.OPERATOR) {
			const root: FilterTreeOperatorDto = new FilterTreeOperatorDto(FilterDtoBuilder.OPERATOR, filterObject.operator);
			const children: Array<AbstractFilterElementDto> = [];

			for (const child of filterObject.children) {
				const childFilterTreeDto = FilterTreeDtoFactory.traverseFilterTreeJson(child);
				children.push(childFilterTreeDto);
			}

			root.children = children;
			treeElement = root;
		} else {
			treeElement = FilterTreeDtoFactory.getFilterDto(filterObject);
		}

		return treeElement;
	}

	private static getFilterDto(filterJson: any): FilterDto {
		if (!(filterJson.filterType in FilterTreeDtoFactory.filterTypeDelegate)) {
			console.error('Unknown filter type ' + filterJson.filterType);
			throw new Error('Unknown filter type ' + filterJson.filterType);
		}

		return FilterTreeDtoFactory.filterTypeDelegate[filterJson.filterType](filterJson);
	}

	private static inFilter(filterObject: any): InFilterDto {
		let filterType: string = FilterDtoBuilder.IN;
		const values = filterObject.values;

		if (values.length === 1 && !values[0]) {
			filterType = filterObject.isExcluded ? FilterDtoBuilder.SPECIFIED : FilterDtoBuilder.UNSPECIFIED;
		}

		const inFilterDto = new InFilterDto(FilterDtoBuilder.FILTER, filterType, filterObject.onCategoryItemId);
		inFilterDto.values = values;

		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(inFilterDto, filterObject);

		return inFilterDto;
	}

	private static isCurrentUser(filterObject: any): IsCurrentUserFilterDto {
		const filterDto = new IsCurrentUserFilterDto(FilterDtoBuilder.FILTER, filterObject.filterType,
			filterObject.onCategoryItemId);

		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(filterDto, filterObject);
		return filterDto;
	}

	private static timePeriodFilter(filterObject: any): TimePeriodFilterDto {
		const filterDto = new TimePeriodFilterDto(FilterDtoBuilder.FILTER, filterObject.filterType,
			filterObject.onCategoryItemId, filterObject.value);

		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(filterDto, filterObject);
		return filterDto;
	}

	private static is(filterObject: any) {
		let filter;

		filter = typeof filterObject.onMeasureItemId === 'undefined' ?
			new IsFilterDto(FilterDtoBuilder.FILTER, filterObject.filterType, filterObject.onCategoryItemId,
				filterObject.value) :
			new ExactFilterDto(filterObject.onMeasureItemId, filterObject.value, filterObject.currencyId,
				filterObject.byCategoryItemId);

		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(filter, filterObject);

		return filter;
	}

	private static contains(filterObject: any) {
		const filter = new ContainsFilterDto(filterObject.itemId, filterObject.value);
		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(filter, filterObject);
		return filter;
	}

	private static startsWith(filterObject: any) {
		const filter = new StartsWithFilterDto(filterObject.itemId, filterObject.value);
		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(filter, filterObject);
		return filter;
	}

	private static endsWith(filterObject: any) {
		const filter = new EndsWithFilterDto(filterObject.itemId, filterObject.value);
		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(filter, filterObject);
		return filter;
	}

	private static between(filterObject: any) {
		let betweenFilter;

		if (typeof filterObject.onCategoryItemId === 'undefined') {
			betweenFilter = new MeasureRangeFilterDto(filterObject.filterType, filterObject.onMeasureItemId,
				filterObject.valueFrom, filterObject.valueTo, filterObject.inclusive,
				filterObject.currencyId, filterObject.byCategoryItemId);
		} else {
			betweenFilter = new CategoryRangeFilterDto(FilterDtoBuilder.FILTER, filterObject.filterType,
				filterObject.onCategoryItemId, filterObject.valueFrom, filterObject.valueTo,
				filterObject.inclusive);
		}

		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(betweenFilter, filterObject);

		return betweenFilter;
	}

	private static rangeFilter(filterObject: any) {
		let filter: any;
		let valueFrom: any;
		let valueTo: any;

		if (filterObject.filterType === 'greaterThan' || filterObject.filterType === 'greaterThanOrEqual') {
			valueFrom = filterObject.valueFrom;
			valueTo = null;
		} else {
			valueFrom = null;
			valueTo = filterObject.valueTo;
		}

		filter = new MeasureRangeFilterDto(filterObject.filterType, filterObject.onMeasureItemId, valueFrom, valueTo, filterObject.inclusive,
			filterObject.currencyId, filterObject.byCategoryItemId);

		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(filter, filterObject);
		return filter;
	}

	private static dateRangeFilter(filterObject: any) {
		let filter: any;
		let valueFrom: any;
		let valueTo: any;

		if (filterObject.filterType === 'before' || filterObject.filterType === 'onOrBefore') {
			valueFrom = null;
			valueTo = filterObject.valueTo;
		} else {
			valueFrom = filterObject.valueFrom;
			valueTo = null;
		}

		if (typeof filterObject.onCategoryItemId === 'undefined') {
			filter = new MeasureRangeFilterDto(filterObject.filterType, filterObject.onMeasureItemId, valueFrom,
				valueTo, filterObject.inclusive, filterObject.currencyId, filterObject.byCategoryItemId);
		} else {
			filter = new CategoryRangeFilterDto(FilterDtoBuilder.FILTER, filterObject.filterType,
				filterObject.onCategoryItemId, valueFrom, valueTo, filterObject.inclusive);
		}

		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(filter, filterObject);
		return filter;
	}

	private static top(filterObject: any) {
		const topFilter = new TopFilterDto(FilterDtoBuilder.FILTER, FilterDtoBuilder.TOP, filterObject.onCategoryItemId,
			filterObject.topCount, filterObject.byMeasureItemId);

		FilterTreeDtoFactory.setFilterExcludedAndIsHiddenForUser(topFilter, filterObject);
		return topFilter;
	}

	private static setFilterExcludedAndIsHiddenForUser(filterDto: FilterDto, filterObject: any): void {
		filterDto.excluded = filterObject.excluded;
		filterDto.isHiddenForUser = filterObject.isHiddenForUser;
	}
}
