import {AbstractCriterionRange} from './abstract-criterion-range';
import {CriterionHelper} from '../helper/criterion-helper';

export class CriterionNumberRange extends AbstractCriterionRange<number> {

	public get valueFrom(): number {
		return this.getValueFrom();
	}

	public set valueFrom(valueFrom: number) {
		this.setValueFrom(CriterionHelper.ifNumber(valueFrom));
	}

	public get valueTo(): number {
		return this.getValueTo();
	}

	public set valueTo(valueTo: number) {
		this.setValueTo(CriterionHelper.ifNumber(valueTo));
	}

}
