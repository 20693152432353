import {ComboboxOption} from './combobox-option';
import {ComboboxGroup} from './combobox-group';
import {ComboboxOptionWithColor} from './combobox-option-with-color';

/**
 * Helper type to host multiple {@link ComboboxOption}s, actually an array of {@link ComboboxOption} items.
 */
export class ComboboxOptions<T extends ComboboxOption<any, any> | ComboboxOptionWithColor<any, any> | ComboboxGroup<any, any>> extends Array<T> {

}
