import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {AbstractCriterion} from './abstract-criterion';
import {FilterItem} from '../filter/filter-item';

export abstract class AbstractCriterionValue<T> extends AbstractCriterion {

	constructor(filterItem: FilterItem, isNegating?: boolean, private _value: T = null) {
		super(filterItem, isNegating);

		PreconditionCheck.notUndefined(_value);
	}

	public get value(): T {
		return this.getValue();
	}

	public set value(value: T) {
		this.setValue(value);
	}

	protected getValue(): T {
		return this._value;
	}

	protected setValue(value: T) {
		this._value = value;

		this.validate();
	}

}
