import {FilterDto} from '../dto/filter-dto';
import {FilterDtoBuilder} from './filter-dto-builder';
import {TopFilterDto} from '../dto/top-filter-dto';
import {IsFilterDto} from '../dto/is-filter-dto';
import {CategoryRangeFilterDto} from '../dto/category-range-filter-dto';
import {TimePeriodFilterDto} from '../dto/time-period-filter-dto';
import {InFilterDto} from '../dto/in-filter-dto';
import {BooleanExpression} from './boolean-expression';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {ContainsFilterDto} from '../dto/contains-filter-dto';
import {StartsWithFilterDto} from '../dto/starts-with-filter-dto';
import {IsCurrentUserFilterDto} from '../dto/is-current-user-filter-dto';

/**
 * Created by Hayk.Andriasyan on 5/25/2017.
 */
export class CategoryItem<T extends BooleanExpression> {

	private filter: FilterDto;
	private categoryItemId: number|string;
	private parentOperator: T;
	private isExcluded = false;
	private isHidden = false;

	constructor(parentOperator: T, categoryItemId: number|string) {
		this.parentOperator = parentOperator;
		this.categoryItemId = categoryItemId;
	}

	private static _formatDate(date: Date): string {
		const day = ('0' + date.getDate()).slice(-2);
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const year = date.getFullYear();

		return `${year}-${month}-${day}`;
	}

	addFilterToParentOperator(): void {
		this.parentOperator.addFilter(this.getFilter());
	}

	in(values: Set<number>): T {
		PreconditionCheck.notNullOrUndefined(values);

		const valuesArray = Array.from(values);

		if (valuesArray.length === 0) {
			return this.parentOperator;
		}

		let filterType: string = FilterDtoBuilder.IN;
		if (valuesArray.length === 1 && (valuesArray[0] === null || typeof valuesArray[0] === 'undefined')) {
			filterType = FilterDtoBuilder.UNSPECIFIED;
		}

		const filter: InFilterDto = new InFilterDto(FilterDtoBuilder.FILTER, filterType, this.categoryItemId);

		filter.values = filter.values.concat(valuesArray);
		return this._getParentOperator(filter);
	}

	isCurrent(): T {
		const filter: TimePeriodFilterDto = new TimePeriodFilterDto(FilterDtoBuilder.FILTER, FilterDtoBuilder.IS_CURRENT,
			this.categoryItemId, 0);

		return this._getParentOperator(filter);
	}

	isCurrentUser(): T {
		const filter: IsCurrentUserFilterDto = new IsCurrentUserFilterDto(FilterDtoBuilder.FILTER, FilterDtoBuilder.IS_CURRENT_USER,
			this.categoryItemId);

		return this._getParentOperator(filter);
	}

	isLast(value: number): T {
		const filter: TimePeriodFilterDto = new TimePeriodFilterDto(FilterDtoBuilder.FILTER, FilterDtoBuilder.IS_LAST,
			this.categoryItemId, value);

		return this._getParentOperator(filter);
	}

	isNext(value: number): T {
		const filter: TimePeriodFilterDto = new TimePeriodFilterDto(FilterDtoBuilder.FILTER, FilterDtoBuilder.IS_NEXT,
			this.categoryItemId, value);

		return this._getParentOperator(filter);
	}

	not(): CategoryItem<T> {
		this.isExcluded = !this.isExcluded;
		return this;
	}

	getFilter(): FilterDto {
		this.filter.excluded = this.isExcluded;
		this.filter.isHiddenForUser = this.isHidden;
		return this.filter;
	}

	between(from: number, to: number): T;
	between(from: Date, to: Date): T;
	between(from: any, to: any): T {
		PreconditionCheck.notNullOrUndefined(from);
		PreconditionCheck.notNullOrUndefined(to);

		let fromValue = from.toString();
		let toValue = to.toString();

		if (typeof from !== 'number') {
			fromValue = CategoryItem._formatDate(from);
			toValue = CategoryItem._formatDate(to);
		}

		return this._createCategoryDateRangeFilter(FilterDtoBuilder.BETWEEN_DATES, fromValue, toValue, true);
	}

	before(start: number | Date): T {
		PreconditionCheck.notNullOrUndefined(start);
		const valueTo = typeof start === 'number' ? start.toString() : CategoryItem._formatDate(start);

		return this._createCategoryDateRangeFilter(FilterDtoBuilder.BEFORE, null, valueTo, false);
	}

	onOrBefore(start: number | Date): T {
		PreconditionCheck.notNullOrUndefined(start);
		const valueTo = typeof start === 'number' ? start.toString() : CategoryItem._formatDate(start);

		return this._createCategoryDateRangeFilter(FilterDtoBuilder.ON_OR_BEFORE, null, valueTo, true);
	}

	after(end: number | Date): T {
		PreconditionCheck.notNullOrUndefined(end);
		const valueFrom = typeof end === 'number' ? end.toString() : CategoryItem._formatDate(end);

		return this._createCategoryDateRangeFilter(FilterDtoBuilder.AFTER, valueFrom, null, false);
	}

	onOrAfter(end: number | Date): T {
		PreconditionCheck.notNullOrUndefined(end);
		const valueFrom = typeof end === 'number' ? end.toString() : CategoryItem._formatDate(end);

		return this._createCategoryDateRangeFilter(FilterDtoBuilder.ON_OR_AFTER, valueFrom, null, true);
	}


	is(date: number | Date): T {
		PreconditionCheck.notNullOrUndefined(date);
		const dateValue = typeof date === 'number' ? date.toString() : CategoryItem._formatDate(date);
		const filter: IsFilterDto = new IsFilterDto(FilterDtoBuilder.FILTER, FilterDtoBuilder.IS, this.categoryItemId, dateValue);
		return this._getParentOperator(filter);
	}

	top(measureItemId: number|string, topCount: number): T {
		PreconditionCheck.notNullOrUndefined(measureItemId);
		PreconditionCheck.notNullOrUndefined(topCount);
		const filter: TopFilterDto = new TopFilterDto(FilterDtoBuilder.FILTER, FilterDtoBuilder.TOP, this.categoryItemId,
			topCount, measureItemId);

		return this._getParentOperator(filter);
	}

	contains(pattern: string): T {
		PreconditionCheck.notNullOrUndefined(pattern);
		const filter: ContainsFilterDto = new ContainsFilterDto(this.categoryItemId, pattern.trim());
		return this._getParentOperator(filter);
	}

	startsWith(prefix: string): T {
		PreconditionCheck.notNullOrUndefined(prefix);
		const filter: StartsWithFilterDto = new StartsWithFilterDto(this.categoryItemId, prefix.trim());
		return this._getParentOperator(filter);
	}

	isHiddenFromUser(isHidden: boolean): CategoryItem<T> {
		this.isHidden = isHidden;
		return this;
	}

	private _createCategoryDateRangeFilter(filterType: string, valueFrom: string, valueTo: string, inclusive: boolean): T {
		return this._getParentOperator(new CategoryRangeFilterDto(FilterDtoBuilder.FILTER, filterType,
			this.categoryItemId, valueFrom, valueTo, inclusive));
	}

	private _getParentOperator(filter: FilterDto): T {
		this.filter = filter;
		this.addFilterToParentOperator();
		return this.parentOperator;
	}
}
