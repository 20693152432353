import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {ComboboxOption} from '../../../combobox-option';
import {FilterType} from './filter-type';

/**
 * A specialized extension of {@link ComboboxOption} class that uses {@link FilterType} as main identifying value and
 * a corresponding message key for human readable display data.
 * @see MessageService
 */
export class FilterTypeOption extends ComboboxOption<FilterType, string> {

	/**
	 * The message key prefix to be used for display value.
	 * @type {string}
	 */
	private static readonly NAME_MESSAGE_KEY_PREFIX: string = 'reporting.controls.filter.type.';

	/**
	 * Constructs the option based on the input parameters.
	 * @param {!FilterType} value - The method of filtering.
	 * @param {boolean} [isDefault=false] - Whether this option should be the default one.
	 */
	constructor(public value: FilterType, public isDefault: boolean = false) {
		super(value, FilterTypeOption.NAME_MESSAGE_KEY_PREFIX + FilterType[value]);
	}

	/**
	 * Acts like a copy-constructor: creates new instance based on another one.
	 * @param {!FilterTypeOption} filterTypeOption - The source option.
	 * @returns {FilterTypeOption} Newly created duplicate of the option provided.
	 */
	public static from(filterTypeOption: FilterTypeOption): FilterTypeOption {
		PreconditionCheck.notNullOrUndefined(filterTypeOption);

		return new FilterTypeOption(filterTypeOption.value, filterTypeOption.isDefault);
	}

}
