import {AbstractCategoryFilterDto} from './abstract-category-filter-dto';

/**
 * Filter category ordered by given measure and selects top of them.
 *
 * Created by Hayk.Andriasyan on 5/23/2017.
 */
export class TopFilterDto extends AbstractCategoryFilterDto {

	/**
	 * Returns number of top selected items.
	 */
	public readonly topCount: number;

	/**
	 * MeasureItemDto id by which filter must be ordered.
	 */
	public readonly byMeasureItemId: number|string;

	public constructor(type: string, filterType: string, onCategoryItemId: number|string, topCount: number, byMeasureItemId: number|string) {
		super(type, filterType, onCategoryItemId);
		this.topCount = topCount;
		this.byMeasureItemId = byMeasureItemId;
	}

}
