import {Observable} from 'rxjs/Observable';
import {Pipe, PipeTransform} from '@angular/core';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import 'rxjs/add/operator/switchMap';

/**
 * Angular {@link PipeTransform} implementation that transforms a {@link MultilingualString} or {@link Map} of values
 * associated by a language identity into an appropriate text in current language. To accommodate this behavior
 * {@link CurrentLanguageProvider} should be injected and used.
 * This pipe is "pure" for performance considerations.
 * @see MessageService
 */
@Pipe({
	name: 'multilingual',
	pure: true
})
export class MultilingualPipe implements PipeTransform {

	/**
	 * Creates the pipe component.
	 * @param {!CurrentLanguageProvider} currentLanguageProvider - An instance of {@link CurrentLanguageProvider} should
	 * be injected.
	 */
	public constructor(private currentLanguageProvider: CurrentLanguageProvider) {
	}

	/**
	 * The transformation function imposed by the {@link PipeTransform} interface.
	 * @param {MultilingualString | Map<number, string>} value - Transformation subject: the multilingual value.
	 * @returns {Observable<string>} Transformation object: the actual text corresponding to the current language.
	 * @override
	 */
	transform(value: MultilingualString | Map<number, string>): Observable<string> {
		const observable: Observable<Language> = this.currentLanguageProvider.getCurrentLanguage();

		return observable.switchMap((language: Language) => {
			let result = '';

			if (value instanceof MultilingualString) {
				result = value.getValue(language.getId());
			} else if (value instanceof Map) {
				result = value.get(language.getId());
			}

			return Observable.of(result);
		});
	}

}
