import {CategoryType} from '../../item/type/category-type';
import {MeasureType} from '../../item/type/measure-type';
import {DateCategoryType} from '../../item/type/date-category-type';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {FilterType} from './filter-type';
import {FilterTypeOption} from './filter-type-option';
import {ComboboxOptions} from '../../../combobox-options';

/**
 * Helper type to host multiple {@link FilterTypeOption}s, actually an array of {@link FilterTypeOption} items, yet
 * extends a more common structure to achieve optimal code reuse.
 */
export abstract class FilterTypeOptions extends ComboboxOptions<FilterTypeOption> {

	/**
	 * Knowledge-like mapping of category to associated filtering methods. Some categories may not be shown in the UI or
	 * not yet implemented, thus association is not defined (or commented out).
	 * @type {Map<CategoryType, FilterTypeOptions>}
	 */
	public static readonly CATEGORY: Map<CategoryType, FilterTypeOptions> = new Map([
		[CategoryType.STANDARD, [
			new FilterTypeOption(FilterType.AMONG, true),
			new FilterTypeOption(FilterType.NOT_AMONG),
			new FilterTypeOption(FilterType.SPECIFIED),
			new FilterTypeOption(FilterType.UNSPECIFIED),
			new FilterTypeOption(FilterType.TOP),
			new FilterTypeOption(FilterType.CONTAINS),
			new FilterTypeOption(FilterType.NOT_CONTAINS),
			new FilterTypeOption(FilterType.BEGINS_WITH)
		]],
		[CategoryType.ME_ASSETS, [
			new FilterTypeOption(FilterType.AMONG, true),
			new FilterTypeOption(FilterType.NOT_AMONG),
			new FilterTypeOption(FilterType.SPECIFIED),
			new FilterTypeOption(FilterType.UNSPECIFIED)
		]],
		[CategoryType.DOCUMENT, []],
		[CategoryType.DATE, [
			new FilterTypeOption(FilterType.AMONG),
			new FilterTypeOption(FilterType.NOT_AMONG),
			new FilterTypeOption(FilterType.SPECIFIED),
			new FilterTypeOption(FilterType.UNSPECIFIED),
			new FilterTypeOption(FilterType.CURRENT),
			new FilterTypeOption(FilterType.NOT_CURRENT),
			new FilterTypeOption(FilterType.PREVIOUS),
			new FilterTypeOption(FilterType.NEXT),
			new FilterTypeOption(FilterType.BETWEEN, true),
			new FilterTypeOption(FilterType.NOT_BETWEEN),
			new FilterTypeOption(FilterType.ON_OR_BEFORE),
			new FilterTypeOption(FilterType.ON_OR_AFTER)
		]],
		[CategoryType.CURRENCY, []],
		[CategoryType.USER, [
			new FilterTypeOption(FilterType.CURRENT_USER, true),
			new FilterTypeOption(FilterType.AMONG),
			new FilterTypeOption(FilterType.NOT_AMONG),
			new FilterTypeOption(FilterType.SPECIFIED),
			new FilterTypeOption(FilterType.UNSPECIFIED)
		]],
	]);

	/**
	 * Knowledge-like mapping of timeline category and its subtypes to associated filtering methods. This is defined
	 * separately from all other category types because timeline category has subtypes as well. Logic behind this is as
	 * follows: if options for select subtype are explicitly defined then these are used, otherwise the options defined
	 * for that category in general ({@link CategoryType.DATE}) are used.
	 * @type {Map<DateCategoryType, FilterTypeOptions>}
	 */
	public static readonly CATEGORY_TIMELINE: Map<DateCategoryType, FilterTypeOptions> = new Map([
		[DateCategoryType.DAY, [
			new FilterTypeOption(FilterType.IS),
			new FilterTypeOption(FilterType.IS_NOT),
			new FilterTypeOption(FilterType.SPECIFIED),
			new FilterTypeOption(FilterType.UNSPECIFIED),
			new FilterTypeOption(FilterType.CURRENT),
			new FilterTypeOption(FilterType.NOT_CURRENT),
			new FilterTypeOption(FilterType.PREVIOUS),
			new FilterTypeOption(FilterType.NEXT),
			new FilterTypeOption(FilterType.BETWEEN, true),
			new FilterTypeOption(FilterType.NOT_BETWEEN),
			new FilterTypeOption(FilterType.ON_OR_BEFORE),
			new FilterTypeOption(FilterType.ON_OR_AFTER),
		]]
	]);

	/**
	 * Knowledge-like mapping of measure to associated filtering methods. Some measures may not be shown in the UI or
	 * not yet implemented, thus association is not defined (or commented out).
	 * @type {Map<MeasureType, FilterTypeOptions>}
	 */
	public static readonly MEASURE: Map<MeasureType, FilterTypeOptions> = new Map([
		[MeasureType.NUMBER, [
			new FilterTypeOption(FilterType.EQUALS, true),
			new FilterTypeOption(FilterType.NOT_EQUALS),
			new FilterTypeOption(FilterType.BETWEEN),
			new FilterTypeOption(FilterType.NOT_BETWEEN),
			new FilterTypeOption(FilterType.LESS_THAN),
			new FilterTypeOption(FilterType.LESS_THAN_OR_EQUALS),
			new FilterTypeOption(FilterType.GREATER_THAN),
			new FilterTypeOption(FilterType.GREATER_THAN_OR_EQUALS),
		]],
		[MeasureType.DATE, [
			new FilterTypeOption(FilterType.EQUALS),
			new FilterTypeOption(FilterType.NOT_EQUALS),
			// new FilterTypeOption(FilterType.EMPTY),
			// new FilterTypeOption(FilterType.TODAY),
			new FilterTypeOption(FilterType.BETWEEN, true),
			new FilterTypeOption(FilterType.NOT_BETWEEN),
			new FilterTypeOption(FilterType.ON_OR_BEFORE),
			new FilterTypeOption(FilterType.ON_OR_AFTER),
		]],
		[MeasureType.TEXT, [
			new FilterTypeOption(FilterType.CONTAINS, true),
			new FilterTypeOption(FilterType.NOT_CONTAINS),
			new FilterTypeOption(FilterType.BEGINS_WITH),
		]],
		[MeasureType.MONEY, [
			new FilterTypeOption(FilterType.EQUALS, true),
			new FilterTypeOption(FilterType.NOT_EQUALS),
			new FilterTypeOption(FilterType.BETWEEN),
			new FilterTypeOption(FilterType.NOT_BETWEEN),
			new FilterTypeOption(FilterType.LESS_THAN),
			new FilterTypeOption(FilterType.LESS_THAN_OR_EQUALS),
			new FilterTypeOption(FilterType.GREATER_THAN),
			new FilterTypeOption(FilterType.GREATER_THAN_OR_EQUALS),
		]],
		[MeasureType.BOOLEAN, [
			new FilterTypeOption(FilterType.EQUALS, true),
		]],
	]);

	/**
	 * Fetches filtering methods associated with specified input criteria, namely category type and in case of timeline
	 * categories the subtype.
	 * @param {!CategoryType} type - The type of category.
	 * @param {DateCategoryType} [dateType=null] - The subtype of timeline category, irrelevant for other types.
	 * @returns {FilterTypeOptions} Number of options matching our criteria.
	 */
	public static ofCategory(type: CategoryType, dateType: DateCategoryType = null): FilterTypeOptions {
		PreconditionCheck.notNullOrUndefined(type);

		if (type === CategoryType.DATE && FilterTypeOptions.CATEGORY_TIMELINE.has(dateType)) {
			return FilterTypeOptions.CATEGORY_TIMELINE.get(dateType);
		} else if (FilterTypeOptions.CATEGORY.has(type)) {
			return FilterTypeOptions.CATEGORY.get(type);
		} else {
			return [];
		}
	}

	/**
	 * Fetches filtering methods associated with specified input criteria, namely measure type.
	 * @param {MeasureType} type - The type of measure.
	 * @returns {FilterTypeOptions} Number of options matching our criteria.
	 */
	public static ofMeasure(type: MeasureType): FilterTypeOptions {
		return FilterTypeOptions.MEASURE.has(type) ? FilterTypeOptions.MEASURE.get(type) : [];
	}

	/**
	 * Fetches the default filtering method from all the possible methods associated with specified input criteria,
	 * namely category type and in case of timeline categories the subtype.
	 * @param {CategoryType} type - The type of category.
	 * @param {DateCategoryType} [dateType=null] - The subtype of timeline category, irrelevant for other types.
	 * @returns {FilterTypeOption} The default option from all possible ones.
	 */
	public static defaultOfCategory(type: CategoryType, dateType: DateCategoryType = null): FilterTypeOption {
		return FilterTypeOptions.findDefaultFilterType(FilterTypeOptions.ofCategory(type, dateType));
	}

	/**
	 * Fetches the default filtering method from all the possible methods associated with specified input criteria,
	 * namely measure type.
	 * @param {!MeasureType} type - The type of category.
	 * @returns {FilterTypeOption} The default option from all possible ones.
	 */
	public static defaultOfMeasure(type: MeasureType): FilterTypeOption {
		PreconditionCheck.notNullOrUndefined(type);

		return FilterTypeOptions.findDefaultFilterType(FilterTypeOptions.ofMeasure(type));
	}

	/**
	 * Finds the default filtering method from all the methods passed as an argument.
	 * @param {!FilterTypeOptions} options - Number of options to choose the default one from.
	 * @returns {FilterTypeOption} The default option from all possible ones.
	 */
	private static findDefaultFilterType(options: FilterTypeOptions): FilterTypeOption {
		PreconditionCheck.notNullOrUndefined(options);

		const defaultOption = options.find((option: FilterTypeOption): boolean => {
			return option.isDefault === true;
		});

		return defaultOption ? defaultOption : null;
	}

}
