import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {MeasureCriterionNumber} from './measure-criterion-number';
import {FilterItem} from '../filter/filter-item';
import {AbstractMoneyCriterion} from './abstract-money-criterion';

/**
 * For money measure 'equals' etc.
 */
export class MeasureCriterionMoney extends MeasureCriterionNumber implements AbstractMoneyCriterion {

	constructor(filterItem: FilterItem, isNegating?: boolean, value?: number, byCategoryId?: number|string, private _currencyId: number = null) {
		super(filterItem, isNegating, value, byCategoryId);

		PreconditionCheck.notUndefined(_currencyId);
	}

	public get currencyId(): number {
		return this._currencyId;
	}

	public set currencyId(currencyId: number) {
		this._currencyId = currencyId;

		this.validate();
	}

}
