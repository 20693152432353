import {FilterType} from '../filter/type/filter-type';

import {And} from '../filter/builder/and';
import {FilterDtoBuilder} from '../filter/builder/filter-dto-builder';
import {Undecided} from '../filter/builder/undecided';
import {AbstractCategoryFilterDto} from '../filter/dto/abstract-category-filter-dto';
import {AbstractMeasureFilterDto} from '../filter/dto/abstract-measure-filter-dto';
import {CategoryRangeFilterDto} from '../filter/dto/category-range-filter-dto';
import {ContainsFilterDto} from '../filter/dto/contains-filter-dto';
import {ExactFilterDto} from '../filter/dto/exact-filter-dto';
import {FilterDto} from '../filter/dto/filter-dto';
import {FilterTreeDto} from '../filter/dto/filter-tree-dto';
import {InFilterDto} from '../filter/dto/in-filter-dto';
import {IsFilterDto} from '../filter/dto/is-filter-dto';
import {MeasureRangeFilterDto} from '../filter/dto/measure-range-filter-dto';
import {StartsWithFilterDto} from '../filter/dto/starts-with-filter-dto';
import {TimePeriodFilterDto} from '../filter/dto/time-period-filter-dto';
import {TopFilterDto} from '../filter/dto/top-filter-dto';

import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {CategoryItemDto} from '../item/dto/category-item-dto';
import {CategoryType} from '../item/type/category-type';
import {DateCategoryType} from '../item/type/date-category-type';
import {MeasureItemDto} from '../item/dto/measure-item-dto';
import {MeasureType} from '../item/type/measure-type';
import {ItemDto} from '../item/dto/item-dto';

import {ItemsMap} from '../../items-map';
import {FilterGroup} from '../filter/filter-group';
import {FilterItem} from '../filter/filter-item';
import {CriterionIntoDTO} from '../visitor/criterion-into-dto';
import {FilterTypeNegations} from '../filter/type/filter-type-negations';
import {AbstractCriterion} from '../criterion/abstract-criterion';
import {CriterionValidator} from '../visitor/criterion-validator';
import {CriterionNumbers} from '../criterion/criterion-numbers';
import {CategoryCriterionNumber} from '../criterion/category-criterion-number';
import {CriterionString} from '../criterion/criterion-string';
import {CriterionNumber} from '../criterion/criterion-number';
import {CriterionDateRange} from '../criterion/criterion-date-range';
import {CriterionNumberRange} from '../criterion/criterion-number-range';
import {RangeBounding} from '../criterion/range-bounding';
import {MeasureCriterionNumber} from '../criterion/measure-criterion-number';
import {MeasureCriterionNumberRange} from '../criterion/measure-criterion-number-range';
import {CriterionDate} from '../criterion/criterion-date';
import {MeasureCriterionMoney} from '../criterion/measure-criterion-money';
import {MeasureCriterionMoneyRange} from '../criterion/measure-criterion-money-range';
import {CommonFilterComponent} from '../../../controls/common-filter/common-filter.component';
import {CriterionHelper} from './criterion-helper';

export abstract class FilterHelper {

	public static filterGroupsToDTO(filterGroups: Array<FilterGroup>): FilterTreeDto {
		let overallResult: FilterTreeDto = FilterDtoBuilder.createOrFilterBuilder().build();
		let isFirstGroup = true;

		filterGroups.forEach((filterGroup: FilterGroup): void => {
			if (filterGroup.isTemporary()) {
				return;
			}

			let groupResult: FilterTreeDto;
			const groupBuilder: FilterDtoBuilder = FilterDtoBuilder.createAndFilterBuilder();
			const expressionTail: Undecided | And = null;

			filterGroup.filterItems.forEach((filterItem: FilterItem): void => {
				if (filterItem.isTemporary()) {
					return;
				}

				const appender: CriterionIntoDTO = new CriterionIntoDTO(filterItem, groupBuilder, expressionTail);
				filterItem.criterion.accept(appender);
			});

			groupResult = groupBuilder.build();

			if (isFirstGroup) {
				isFirstGroup = false;

				overallResult = groupResult;
			} else {
				overallResult = FilterDtoBuilder.combineOr(overallResult, groupResult);
			}
		});

		return overallResult;
	}

	public static isNegatingFromDTO(filterElement: FilterDto): boolean {
		PreconditionCheck.notNullOrUndefined(filterElement);

		return filterElement.excluded;
	}

	public static itemFromDTO(filterElement: FilterDto, items: ItemsMap): ItemDto {
		PreconditionCheck.notNullOrUndefined(filterElement);
		PreconditionCheck.notNullOrUndefined(items);

		let result: ItemDto = null;

		if (filterElement instanceof AbstractCategoryFilterDto) {
			result = items.get(filterElement.onCategoryItemId);
		} else if (filterElement instanceof AbstractMeasureFilterDto) {
			result = items.get(filterElement.onMeasureItemId);
		} else if (filterElement instanceof FilterDto) {
			if (filterElement instanceof ContainsFilterDto) {
				result = items.get(filterElement.itemId);
			} else if (filterElement instanceof StartsWithFilterDto) {
				result = items.get(filterElement.itemId);
			}
		}

		return result;
	}

	public static filterTypeFromDTO(filterElement: FilterDto): FilterType {
		PreconditionCheck.notNullOrUndefined(filterElement);

		let result: FilterType = null;
		const filterType: string = filterElement.filterType;
		const isNot: boolean = FilterHelper.isNegatingFromDTO(filterElement);

		if (filterType === FilterDtoBuilder.BETWEEN_DATES) {
			result = isNot ? FilterType.NOT_BETWEEN : FilterType.BETWEEN;
		} else if (filterType === FilterDtoBuilder.ON_OR_BEFORE) {
			result = FilterType.ON_OR_BEFORE;
		} else if (filterType === FilterDtoBuilder.ON_OR_AFTER) {
			result = FilterType.ON_OR_AFTER;
		} else if (filterType === FilterDtoBuilder.IN) {
			result = isNot ? FilterType.NOT_AMONG : FilterType.AMONG;
		} else if (filterType === FilterDtoBuilder.UNSPECIFIED) {
			result = isNot ? FilterType.SPECIFIED : FilterType.UNSPECIFIED;
		} else if (filterType === FilterDtoBuilder.IS) {
			result = isNot ? FilterType.IS_NOT : FilterType.IS;
		} else if (filterType === FilterDtoBuilder.IS_CURRENT) {
			result = isNot ? FilterType.NOT_CURRENT : FilterType.CURRENT;
		} else if (filterType === FilterDtoBuilder.IS_LAST) {
			result = FilterType.PREVIOUS;
		} else if (filterType === FilterDtoBuilder.IS_NEXT) {
			result = FilterType.NEXT;
		} else if (filterType === FilterDtoBuilder.TOP) {
			result = FilterType.TOP;
		} else if (filterType === ContainsFilterDto.CONTAINS) {
			result = isNot ? FilterType.NOT_CONTAINS : FilterType.CONTAINS;
		} else if (filterType === StartsWithFilterDto.STARTS_WITH) {
			result = FilterType.BEGINS_WITH;
		} else if (filterType === ExactFilterDto.EXACT) {
			result = isNot ? FilterType.NOT_EQUALS : FilterType.EQUALS;
		} else if (filterType === FilterDtoBuilder.BETWEEN) {
			result = isNot ? FilterType.NOT_BETWEEN : FilterType.BETWEEN;
		} else if (filterType === FilterDtoBuilder.LESS_THAN) {
			result = FilterType.LESS_THAN;
		} else if (filterType === FilterDtoBuilder.LESS_THAN_OR_EQUAL) {
			result = FilterType.LESS_THAN_OR_EQUALS;
		} else if (filterType === FilterDtoBuilder.GREATER_THAN) {
			result = FilterType.GREATER_THAN;
		} else if (filterType === FilterDtoBuilder.GREATER_THAN_OR_EQUAL) {
			result = FilterType.GREATER_THAN_OR_EQUALS;
		} else if (filterType === FilterDtoBuilder.IS_CURRENT_USER) {
			result = FilterType.CURRENT_USER;
		}

		return result;
	}

	public static filterItemFromDTO(filterComponent: CommonFilterComponent, filterElement: FilterDto): FilterItem {
		PreconditionCheck.notNullOrUndefined(filterComponent);
		PreconditionCheck.notNullOrUndefined(filterElement);

		let filterItem: FilterItem = null;
		const criterionValidator: CriterionValidator = new CriterionValidator();
		const item: ItemDto = FilterHelper.itemFromDTO(filterElement, filterComponent.items);
		const filterType: FilterType = FilterHelper.filterTypeFromDTO(filterElement);

		if (!item) {
			return filterItem;
		} else if (!filterType) {
			throw new Error(`Unhandled filter type (was '${filterElement.filterType}').`);
		}

		filterItem = new FilterItem(filterComponent, criterionValidator, item, filterType);
		filterItem.criterion = FilterHelper.criterionFor(filterItem, filterElement);

		return filterItem;
	}

	public static criterionFor(filterItem: FilterItem, filterElement: FilterDto = null): AbstractCriterion {
		PreconditionCheck.notNullOrUndefined(filterItem);
		PreconditionCheck.notUndefined(filterElement);

		let criterion: AbstractCriterion = null;
		const item: ItemDto = filterItem.item;
		const filterType: FilterType = filterItem.filterType;
		const isNot: boolean = FilterTypeNegations.isNegating(filterType);

		if (item instanceof CategoryItemDto) {
			const categoryType: CategoryType = item.categoryType;
			const dateCategoryType: DateCategoryType = item.dateCategoryType;

			if (categoryType === CategoryType.STANDARD) {
				if (filterType === FilterType.AMONG || filterType === FilterType.NOT_AMONG) {
					const values: Array<number> = filterElement ? (filterElement as InFilterDto).values : [];

					criterion = new CriterionNumbers(filterItem, isNot, values);
				} else if (filterType === FilterType.SPECIFIED || filterType === FilterType.UNSPECIFIED) {
					const values: Array<number> = [null];

					criterion = new CriterionNumbers(filterItem, isNot, values);
				} else if (filterType === FilterType.TOP) {
					const value: number = filterElement ? (filterElement as TopFilterDto).topCount : null;
					const byMeasureItemId: number|string = filterElement ? (filterElement as TopFilterDto).byMeasureItemId : null;

					criterion = new CategoryCriterionNumber(filterItem, isNot, value, byMeasureItemId);
				} else if (filterType === FilterType.CONTAINS || filterType === FilterType.NOT_CONTAINS) {
					const value: string = filterElement ? (filterElement as ContainsFilterDto).value : null;

					criterion = new CriterionString(filterItem, isNot, value);
				} else if (filterType === FilterType.BEGINS_WITH) {
					const value: string = filterElement ? (filterElement as StartsWithFilterDto).value : null;

					criterion = new CriterionString(filterItem, isNot, value);
				}
			} else if (categoryType === CategoryType.ME_ASSETS) {
				if (filterType === FilterType.AMONG || filterType === FilterType.NOT_AMONG) {
					const values: Array<number> = filterElement ? (filterElement as InFilterDto).values : [];

					criterion = new CriterionNumbers(filterItem, isNot, values);
				} else if (filterType === FilterType.SPECIFIED || filterType === FilterType.UNSPECIFIED) {
					const values: Array<number> = [null];

					criterion = new CriterionNumbers(filterItem, isNot, values);
				}
			} else if (categoryType === CategoryType.DATE) {
				if (filterType === FilterType.AMONG || filterType === FilterType.NOT_AMONG) {
					const values: Array<number> = filterElement ? (filterElement as InFilterDto).values : [];

					if (dateCategoryType === DateCategoryType.DAY) {
						throw new Error('Not implemented!');
					} else {
						criterion = new CriterionNumbers(filterItem, isNot, values);
					}
				} else if (filterType === FilterType.IS || filterType === FilterType.IS_NOT) {
					const value: string = filterElement ? (filterElement as IsFilterDto).value : null;

					if (dateCategoryType === DateCategoryType.DAY) {
						criterion = new CriterionDate(filterItem, isNot, CriterionHelper.ifDate(value));
					} else {
						throw new Error('Not implemented!');
					}
				} else if (filterType === FilterType.SPECIFIED || filterType === FilterType.UNSPECIFIED) {
					const values: Array<number> = [null];

					criterion = new CriterionNumbers(filterItem, isNot, values);
				} else if (filterType === FilterType.CURRENT || filterType === FilterType.NOT_CURRENT) {
					const value = 0;

					criterion = new CriterionNumber(filterItem, isNot, value);
				} else if (filterType === FilterType.PREVIOUS) {
					const value: number = filterElement ? (filterElement as TimePeriodFilterDto).value : null;

					criterion = new CriterionNumber(filterItem, isNot, value);
				} else if (filterType === FilterType.NEXT) {
					const value: number = filterElement ? (filterElement as TimePeriodFilterDto).value : null;

					criterion = new CriterionNumber(filterItem, isNot, value);
				} else if (filterType === FilterType.BETWEEN || filterType === FilterType.NOT_BETWEEN) {
					const valueFrom: string = filterElement ? (filterElement as CategoryRangeFilterDto).valueFrom : null;
					const valueTo: string = filterElement ? (filterElement as CategoryRangeFilterDto).valueTo : null;

					if (dateCategoryType === DateCategoryType.DAY) {
						criterion = new CriterionDateRange(filterItem, isNot, true, null, CriterionHelper.ifDate(valueFrom), CriterionHelper.ifDate(valueTo));
					} else {
						criterion = new CriterionNumberRange(filterItem, isNot, true, null, CriterionHelper.ifNumber(valueFrom), CriterionHelper.ifNumber(valueTo));
					}
				} else if (filterType === FilterType.ON_OR_BEFORE) {
					const valueTo: string = filterElement ? (filterElement as CategoryRangeFilterDto).valueTo : null;

					if (dateCategoryType === DateCategoryType.DAY) {
						criterion = new CriterionDateRange(filterItem, isNot, true, RangeBounding.UPPER, null, CriterionHelper.ifDate(valueTo));
					} else {
						criterion = new CriterionNumberRange(filterItem, isNot, true, RangeBounding.UPPER, null, CriterionHelper.ifNumber(valueTo));
					}
				} else if (filterType === FilterType.ON_OR_AFTER) {
					const valueFrom: string = filterElement ? (filterElement as CategoryRangeFilterDto).valueFrom : null;

					if (dateCategoryType === DateCategoryType.DAY) {
						criterion = new CriterionDateRange(filterItem, isNot, true, RangeBounding.LOWER, CriterionHelper.ifDate(valueFrom), null);
					} else {
						criterion = new CriterionNumberRange(filterItem, isNot, true, RangeBounding.LOWER, CriterionHelper.ifNumber(valueFrom), null);
					}
				}
			} else if (categoryType === CategoryType.USER) {
				if (filterType === FilterType.AMONG || filterType === FilterType.NOT_AMONG) {
					const values: Array<number> = filterElement ? (filterElement as InFilterDto).values : [];
					criterion = new CriterionNumbers(filterItem, isNot, values);
				} else if (filterType === FilterType.SPECIFIED || filterType === FilterType.UNSPECIFIED) {
					const values: Array<number> = [null];
					criterion = new CriterionNumbers(filterItem, isNot, values);
				} else if (filterType === FilterType.CURRENT_USER) {
					const value = 0;
					criterion = new CriterionNumber(filterItem, isNot, value);
				}
			}

			if (!criterion) {
				throw new Error(`Unhandled combination of category and filter types (were '${CategoryType[filterType]}' and '${FilterType[filterType]}' respectively).`);
			}
		} else if (item instanceof MeasureItemDto) {
			const measureType: MeasureType = item.measureType;
			const byCategoryItemId: number|string = filterElement ? (filterElement as AbstractMeasureFilterDto).byCategoryItemId : null;

			if (measureType === MeasureType.NUMBER) {
				if (filterType === FilterType.EQUALS || filterType === FilterType.NOT_EQUALS) {
					const value: string = filterElement ? (filterElement as ExactFilterDto).value : null;

					criterion = new MeasureCriterionNumber(filterItem, isNot, CriterionHelper.ifNumber(value), byCategoryItemId);
				} else if (filterType === FilterType.BETWEEN || filterType === FilterType.NOT_BETWEEN) {
					const valueFrom: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueFrom : null;
					const valueTo: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueTo : null;

					criterion = new MeasureCriterionNumberRange(filterItem, isNot, true, null, CriterionHelper.ifNumber(valueFrom), CriterionHelper.ifNumber(valueTo), byCategoryItemId);
				} else if (filterType === FilterType.LESS_THAN) {
					const valueTo: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueTo : null;

					criterion = new MeasureCriterionNumberRange(filterItem, isNot, false, RangeBounding.UPPER, null, CriterionHelper.ifNumber(valueTo), byCategoryItemId);
				} else if (filterType === FilterType.LESS_THAN_OR_EQUALS) {
					const valueTo: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueTo : null;

					criterion = new MeasureCriterionNumberRange(filterItem, isNot, true, RangeBounding.UPPER, null, CriterionHelper.ifNumber(valueTo), byCategoryItemId);
				} else if (filterType === FilterType.GREATER_THAN) {
					const valueFrom: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueFrom : null;

					criterion = new MeasureCriterionNumberRange(filterItem, isNot, false, RangeBounding.LOWER, CriterionHelper.ifNumber(valueFrom), null, byCategoryItemId);
				} else if (filterType === FilterType.GREATER_THAN_OR_EQUALS) {
					const valueFrom: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueFrom : null;

					criterion = new MeasureCriterionNumberRange(filterItem, isNot, true, RangeBounding.LOWER, CriterionHelper.ifNumber(valueFrom), null, byCategoryItemId);
				}
			} else if (measureType === MeasureType.DATE) {
				if (filterType === FilterType.EQUALS || filterType === FilterType.NOT_EQUALS) {
					const value: string = filterElement ? (filterElement as ExactFilterDto).value : null;

					criterion = new CriterionDate(filterItem, isNot, CriterionHelper.ifDate(value));
				} else if (filterType === FilterType.EMPTY) {
					throw new Error('Not implemented!');
				} else if (filterType === FilterType.TODAY) {
					throw new Error('Not implemented!');
				} else if (filterType === FilterType.BETWEEN || filterType === FilterType.NOT_BETWEEN) {
					const valueFrom: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueFrom : null;
					const valueTo: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueTo : null;

					criterion = new CriterionDateRange(filterItem, isNot, true, null, CriterionHelper.ifDate(valueFrom), CriterionHelper.ifDate(valueTo));
				} else if (filterType === FilterType.ON_OR_BEFORE) {
					const valueTo: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueTo : null;

					criterion = new CriterionDateRange(filterItem, isNot, true, RangeBounding.UPPER, null, CriterionHelper.ifDate(valueTo));
				} else if (filterType === FilterType.ON_OR_AFTER) {
					const valueFrom: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueFrom : null;

					criterion = new CriterionDateRange(filterItem, isNot, true, RangeBounding.LOWER, CriterionHelper.ifDate(valueFrom), null);
				}
			} else if (measureType === MeasureType.TEXT) {
				if (filterType === FilterType.CONTAINS || filterType === FilterType.NOT_CONTAINS) {
					const value: string = filterElement ? (filterElement as ContainsFilterDto).value : null;

					criterion = new CriterionString(filterItem, isNot, value);
				} else if (filterType === FilterType.BEGINS_WITH) {
					const value: string = filterElement ? (filterElement as StartsWithFilterDto).value : null;

					criterion = new CriterionString(filterItem, isNot, value);
				}
			} else if (measureType === MeasureType.MONEY) {
				const currencyId: number = filterElement ? (filterElement as AbstractMeasureFilterDto).currencyId : filterItem.parent.defaultCurrencyID;

				if (filterType === FilterType.EQUALS || filterType === FilterType.NOT_EQUALS) {
					const value: string = filterElement ? (filterElement as ExactFilterDto).value : null;

					criterion = new MeasureCriterionMoney(filterItem, isNot, CriterionHelper.ifNumber(value), byCategoryItemId, currencyId);
				} else if (filterType === FilterType.BETWEEN || filterType === FilterType.NOT_BETWEEN) {
					const valueFrom: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueFrom : null;
					const valueTo: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueTo : null;

					criterion = new MeasureCriterionMoneyRange(filterItem, isNot, true, null, CriterionHelper.ifNumber(valueFrom), CriterionHelper.ifNumber(valueTo), byCategoryItemId, currencyId);
				} else if (filterType === FilterType.LESS_THAN) {
					const valueTo: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueTo : null;

					criterion = new MeasureCriterionMoneyRange(filterItem, isNot, false, RangeBounding.UPPER, null, CriterionHelper.ifNumber(valueTo), byCategoryItemId, currencyId);
				} else if (filterType === FilterType.LESS_THAN_OR_EQUALS) {
					const valueTo: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueTo : null;

					criterion = new MeasureCriterionMoneyRange(filterItem, isNot, true, RangeBounding.UPPER, null, CriterionHelper.ifNumber(valueTo), byCategoryItemId, currencyId);
				} else if (filterType === FilterType.GREATER_THAN) {
					const valueFrom: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueFrom : null;

					criterion = new MeasureCriterionMoneyRange(filterItem, isNot, false, RangeBounding.LOWER, CriterionHelper.ifNumber(valueFrom), null, byCategoryItemId, currencyId);
				} else if (filterType === FilterType.GREATER_THAN_OR_EQUALS) {
					const valueFrom: string = filterElement ? (filterElement as MeasureRangeFilterDto).valueFrom : null;

					criterion = new MeasureCriterionMoneyRange(filterItem, isNot, true, RangeBounding.LOWER, CriterionHelper.ifNumber(valueFrom), null, byCategoryItemId, currencyId);
				}
			} else if (measureType === MeasureType.BOOLEAN) {
				const value: string = filterElement ? (filterElement as ExactFilterDto).value : null;

				criterion = new CriterionNumber(filterItem, isNot, CriterionHelper.ifNumber(value));
			}

			if (!criterion) {
				throw new Error(`Unhandled combination of measure and filter types (were '${MeasureType[measureType]}' and '${FilterType[filterType]}' respectively).`);
			}
		}

		if (!criterion) {
			throw new Error(`Unhandled item type (was '${item.constructor.name}').`);
		}

		return criterion;
	}

}
