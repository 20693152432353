/**
 * Base class for filters and operators DTOs.
 *
 * Created by Hayk.Andriasyan on 5/23/2017.
 */
export class AbstractFilterElementDto {

	public readonly type: string;

	public constructor(type: string) {
		this.type = type;
	}
}
