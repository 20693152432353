import {AbstractCategoryFilterDto} from './abstract-category-filter-dto';

/**
 * Range selection filter. Selects value sets which contains the value of the category item holder in given range
 * exclusively. When any of {@link #valueFrom}, {@link #valueTo} is <CODE>null</CODE> the range is considered to be
 * open ended. The filter is not applied if both are <CODE>null</CODE>.
 *
 * Created by Hayk.Andriasyan on 5/24/2017.
 */
export class CategoryRangeFilterDto extends AbstractCategoryFilterDto {

	/**
	 * Lower limit for range.
	 *
	 * The limit or <CODE>null</CODE> if the range does not have lower bound.
	 */
	public readonly valueFrom: string;

	/**
	 * Higher limit for range.
	 *
	 * The limit or <CODE>null</CODE> if the range does not have higher bound.
	 */
	public readonly valueTo: string;

	/**
	 * Boolean flag indicating that the boundaries are included in the range.
	 */
	public readonly inclusive: boolean;

	public constructor(type: string, filterType: string, onCategoryItemId: number|string, valueFrom: string, valueTo: string, inclusive: boolean) {
		super(type, filterType, onCategoryItemId);
		this.valueFrom = valueFrom;
		this.valueTo = valueTo;
		this.inclusive = inclusive;
	}

}
