import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {MeasureCriterionNumberRange} from './measure-criterion-number-range';
import {RangeBounding} from './range-bounding';
import {FilterItem} from '../filter/filter-item';
import {AbstractMoneyCriterion} from './abstract-money-criterion';

/**
 * For money measure 'between', 'less than', 'less than or equals', 'greater than', 'greater than or equals' etc.
 */
export class MeasureCriterionMoneyRange extends MeasureCriterionNumberRange implements AbstractMoneyCriterion {

	constructor(filterItem: FilterItem, isNegating?: boolean, isIncluding?: boolean, bounding?: RangeBounding, valueFrom?: number, valueTo?: number, byCategoryId?: number|string, private _currencyId: number = null) {
		super(filterItem, isNegating, isIncluding, bounding, valueFrom, valueTo, byCategoryId);

		PreconditionCheck.notUndefined(_currencyId);
	}

	public get currencyId(): number {
		return this._currencyId;
	}

	public set currencyId(currencyId: number) {
		this._currencyId = currencyId;

		this.validate();
	}

}
