/**
 * Lists filtering methods: how value should be filtered.
 */
export enum FilterType {
	AMONG = 1,
	NOT_AMONG,
	SPECIFIED,
	UNSPECIFIED,
	CONTAINS,
	NOT_CONTAINS,
	BEGINS_WITH,
	TOP,
	EQUALS,
	NOT_EQUALS,
	BETWEEN,
	NOT_BETWEEN,
	LESS_THAN,
	LESS_THAN_OR_EQUALS,
	GREATER_THAN,
	GREATER_THAN_OR_EQUALS,
	ON_OR_BEFORE,
	ON_OR_AFTER,
	CURRENT,
	NOT_CURRENT,
	PREVIOUS,
	NEXT,
	IS,
	IS_NOT,
	EMPTY,
	TODAY,
	CURRENT_USER
}
