import {AbstractCategoryFilterDto} from './abstract-category-filter-dto';

/**
 * Exact math to the given value.
 *
 * Created by Hayk.Andriasyan on 5/23/2017.
 */
export class IsFilterDto extends AbstractCategoryFilterDto {

	public value: string;

	public constructor(type: string, filterType: string, onCategoryItemId: number|string, value: string) {
		super(type, filterType, onCategoryItemId);
		this.value = value;
	}
}
