import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {FilterType} from './filter-type';

/**
 * Helper to determine whether {@link FilterType} has negative meaning.
 */
export abstract class FilterTypeNegations {

	/**
	 * All the {@link FilterType}s with negative meaning.
	 * @type {FilterType[]}
	 */
	private static readonly NEGATING_FILTER_TYPES: Array<FilterType> = [
		FilterType.NOT_AMONG,
		FilterType.NOT_CONTAINS,
		FilterType.NOT_EQUALS,
		FilterType.NOT_BETWEEN,
		FilterType.NOT_CURRENT,
		FilterType.IS_NOT,
		FilterType.SPECIFIED
	];

	/**
	 * Determines whether specified filtering method has negative meaning.
	 * @param {!FilterType} filterType - The filtering method.
	 * @returns {boolean} True if filtering method is negating, false otherwise.
	 */
	public static isNegating(filterType: FilterType): boolean {
		PreconditionCheck.notNullOrUndefined(filterType);

		return FilterTypeNegations.NEGATING_FILTER_TYPES.indexOf(filterType) !== -1;
	}

}
