import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {CriterionVisitor} from '../visitor/criterion-visitor';
import {FilterItem} from '../filter/filter-item';

/**
 * Base abstract class denoting a basic criterion features that are common for all criteria variations.
 */
export abstract class AbstractCriterion {

	/**
	 * Creates the criteria with initial parameters.
	 * @param {!FilterItem} _filterItem - The item hosting this particular criteria.
	 * @param {boolean} [_isNegating=false] - Whether the associated filtering method is negating.
	 * @see FilterType
	 * @see FilterTypeNegations
	 */
	constructor(private _filterItem: FilterItem, private _isNegating: boolean = false) {
		PreconditionCheck.notNullOrUndefined(_filterItem);
		PreconditionCheck.notNullOrUndefined(_isNegating);
	}

	/**
	 * Returns the item hosting this criteria.
	 * @returns {FilterItem} The hosting item.
	 */
	public get filterItem(): FilterItem {
		return this._filterItem;
	}

	/**
	 * Check whether or not the associated filtering method has negative meaning.
	 * @returns {boolean} True if negative, false otherwise.
	 */
	public get isNegating(): boolean {
		return this._isNegating;
	}

	/**
	 * Specify whether or not the associated filtering method has negative meaning.
	 * @param {boolean} isNegating - True if negative, false otherwise.
	 */
	public set isNegating(isNegating: boolean) {
		this._isNegating = isNegating;
	}

	/**
	 * In accordance to 'visitor' pattern this method is used to provide the visitor instance.
	 * @param {!CriterionVisitor} criterionVisitor - Any implementation of {@CriterionVisitor} interface.
	 */
	public accept(criterionVisitor: CriterionVisitor): void {
		PreconditionCheck.notNullOrUndefined(criterionVisitor);

		criterionVisitor.visit(this);
	}

	/**
	 * Method should be called by descendants when any property of those is changed, in as much as the criteria must be
	 * validated in case of a change. This simulates visiting the criterion by an instance of {@link CriterionValidator}
	 * which is an implementation of the aforementioned interface.
	 */
	protected validate(): void {
		this.accept(this._filterItem.criterionValidator);
	}

}
