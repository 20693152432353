import {AbstractMeasureFilterDto} from './abstract-measure-filter-dto';
import {FilterDtoBuilder} from '../builder/filter-dto-builder';

/**
 * Range selection filter. Selects value sets which contains the value of the measure item holder in given range
 * exclusively. When any of {@link #valueFrom}, {@link #valueTo} is <CODE>null</CODE> the range is considered to be
 * open ended. The filter is not applied if both are <CODE>null</CODE>.
 *
 * Created by Hayk.Andriasyan on 5/24/2017.
 */
export class MeasureRangeFilterDto extends AbstractMeasureFilterDto {

	/**
	 * Lower limit for range.
	 *
	 * The limit or <CODE>null</CODE> if the range does not have lower bound.
	 */
	public readonly valueFrom: string;

	/**
	 * Higher limit for range.
	 *
	 * The limit or <CODE>null</CODE> if the range does not have higher bound.
	 */
	public readonly valueTo: string;

	/**
	 * Boolean flag indicating that the boundaries are included in the range.
	 */
	public readonly inclusive: boolean;

	public constructor(filterType: string, onMeasureItemId: number|string, valueFrom: string, valueTo: string,
					inclusive: boolean, currencyId?: number, byCategoryItemId?: number|string) {
		super(FilterDtoBuilder.FILTER, filterType, onMeasureItemId, byCategoryItemId || null, currencyId || null);
		this.valueFrom = valueFrom;
		this.valueTo = valueTo;
		this.inclusive = inclusive;
	}
}
