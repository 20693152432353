import {CriterionNumber} from './criterion-number';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {AbstractMeasureCriterion} from './abstract-measure-criterion';
import {FilterItem} from '../filter/filter-item';

/**
 * For numeric measure 'equals' etc.
 */
export class MeasureCriterionNumber extends CriterionNumber implements AbstractMeasureCriterion {

	constructor(filterItem: FilterItem, isNegating?: boolean, value?: number, private _byCategoryId: number|string = null) {
		super(filterItem, isNegating, value);

		PreconditionCheck.notUndefined(_byCategoryId);
	}

	public get byCategoryId(): number|string {
		return this._byCategoryId;
	}

	public set byCategoryId(byCategoryId: number|string) {
		this._byCategoryId = byCategoryId;

		this.validate();
	}

}
