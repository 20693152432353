import {PreconditionCheck} from '@synisys/idm-common-util-frontend';

import {And} from '../filter/builder/and';
import {FilterDtoBuilder} from '../filter/builder/filter-dto-builder';
import {Undecided} from '../filter/builder/undecided';
import {CategoryItem} from '../filter/builder/category-item';
import {MeasureItem} from '../filter/builder/measure-item';
import {CategoryItemDto} from '../item/dto/category-item-dto';
import {CategoryType} from '../item/type/category-type';
import {DateCategoryType} from '../item/type/date-category-type';
import {MeasureItemDto} from '../item/dto/measure-item-dto';
import {MeasureType} from '../item/type/measure-type';
import {ItemDto} from '../item/dto/item-dto';

import {CriterionVisitor} from './criterion-visitor';
import {AbstractCriterion} from '../criterion/abstract-criterion';
import {MeasureCriterionMoneyRange} from '../criterion/measure-criterion-money-range';
import {MeasureCriterionNumberRange} from '../criterion/measure-criterion-number-range';
import {CriterionNumberRange} from '../criterion/criterion-number-range';
import {MeasureCriterionMoney} from '../criterion/measure-criterion-money';
import {MeasureCriterionNumber} from '../criterion/measure-criterion-number';
import {CategoryCriterionNumber} from '../criterion/category-criterion-number';
import {CriterionNumber} from '../criterion/criterion-number';
import {CriterionDate} from '../criterion/criterion-date';
import {CriterionDateRange} from '../criterion/criterion-date-range';
import {CriterionNumbers} from '../criterion/criterion-numbers';
import {CriterionString} from '../criterion/criterion-string';
import {FilterItem} from '../filter/filter-item';
import {FilterType} from '../filter/type/filter-type';

export class CriterionIntoDTO implements CriterionVisitor {

	private item: CategoryItem<any> | MeasureItem<any>;

	constructor(private filterItem: FilterItem, private groupBuilder: FilterDtoBuilder, private expressionTail: Undecided | And = null) {
		PreconditionCheck.notNullOrUndefined(filterItem);
		PreconditionCheck.notNullOrUndefined(groupBuilder);
		PreconditionCheck.notUndefined(expressionTail);

		this.item = null;
	}

	public visit(criterion: CategoryCriterionNumber): void;
	public visit(criterion: MeasureCriterionMoney): void;
	public visit(criterion: MeasureCriterionMoneyRange): void;
	public visit(criterion: MeasureCriterionNumber): void;
	public visit(criterion: MeasureCriterionNumberRange): void;
	public visit(criterion: CriterionDate): void;
	public visit(criterion: CriterionDateRange): void;
	public visit(criterion: CriterionNumber): void;
	public visit(criterion: CriterionNumberRange): void;
	public visit(criterion: CriterionNumbers): void;
	public visit(criterion: CriterionString): void;
	public visit(criterion: AbstractCriterion): void {
		PreconditionCheck.notNullOrUndefined(criterion);

		const itemDto: ItemDto = this.filterItem.item;
		const filterType: FilterType = this.filterItem.filterType;
		let expressionTail: Undecided | And = null;

		if (itemDto instanceof CategoryItemDto) {
			const categoryType = itemDto.categoryType;
			const dateCategoryType = itemDto.dateCategoryType;

			const item: CategoryItem<any> = (this.createItem(criterion) as CategoryItem<any>);
			this.item = item;

			if (categoryType === CategoryType.STANDARD) {
				if (filterType === FilterType.AMONG || filterType === FilterType.NOT_AMONG) {
					expressionTail = item.in(new Set((criterion as CriterionNumbers).values));
				} else if (filterType === FilterType.SPECIFIED || filterType === FilterType.UNSPECIFIED) {
					expressionTail = item.in(new Set((criterion as CriterionNumbers).values));
				} else if (filterType === FilterType.TOP) {
					expressionTail = item.top((criterion as CategoryCriterionNumber).byMeasureId, (criterion as CategoryCriterionNumber).value);
				} else if (filterType === FilterType.CONTAINS || filterType === FilterType.NOT_CONTAINS) {
					expressionTail = item.contains((criterion as CriterionString).value);
				} else if (filterType === FilterType.BEGINS_WITH) {
					expressionTail = item.startsWith((criterion as CriterionString).value);
				}
			} else if (categoryType === CategoryType.ME_ASSETS) {
				if (filterType === FilterType.AMONG || filterType === FilterType.NOT_AMONG) {
					expressionTail = item.in(new Set((criterion as CriterionNumbers).values));
				} else if (filterType === FilterType.SPECIFIED || filterType === FilterType.UNSPECIFIED) {
					expressionTail = item.in(new Set((criterion as CriterionNumbers).values));
				}
			} else if (categoryType === CategoryType.DATE) {
				if (filterType === FilterType.AMONG || filterType === FilterType.NOT_AMONG) {
					if (dateCategoryType === DateCategoryType.DAY) {
						throw new Error('Not implemented!');
					} else {
						expressionTail = item.in(new Set((criterion as CriterionNumbers).values));
					}
				} else if (filterType === FilterType.IS || filterType === FilterType.IS_NOT) {
					if (dateCategoryType === DateCategoryType.DAY) {
						expressionTail = item.is((criterion as CriterionDate).value);
					} else {
						throw new Error('Not implemented!');
					}
				} else if (filterType === FilterType.SPECIFIED || filterType === FilterType.UNSPECIFIED) {
					expressionTail = item.in(new Set((criterion as CriterionNumbers).values));
				} else if (filterType === FilterType.CURRENT || filterType === FilterType.NOT_CURRENT) {
					expressionTail = item.isCurrent();
				} else if (filterType === FilterType.PREVIOUS) {
					expressionTail = item.isLast((criterion as CriterionNumber).value);
				} else if (filterType === FilterType.NEXT) {
					expressionTail = item.isNext((criterion as CriterionNumber).value);
				} else if (filterType === FilterType.BETWEEN || filterType === FilterType.NOT_BETWEEN) {
					if (dateCategoryType === DateCategoryType.DAY) {
						expressionTail = item.between((criterion as CriterionDateRange).valueFrom, (criterion as CriterionDateRange).valueTo);
					} else {
						expressionTail = item.between((criterion as CriterionNumberRange).valueFrom, (criterion as CriterionNumberRange).valueTo);
					}
				} else if (filterType === FilterType.ON_OR_BEFORE) {
					if (dateCategoryType === DateCategoryType.DAY) {
						expressionTail = item.onOrBefore((criterion as CriterionDateRange).valueTo);
					} else {
						expressionTail = item.onOrBefore((criterion as CriterionNumberRange).valueTo);
					}
				} else if (filterType === FilterType.ON_OR_AFTER) {
					if (dateCategoryType === DateCategoryType.DAY) {
						expressionTail = item.onOrAfter((criterion as CriterionDateRange).valueFrom);
					} else {
						expressionTail = item.onOrAfter((criterion as CriterionNumberRange).valueFrom);
					}
				}
			} else if (categoryType === CategoryType.USER) {
				if (filterType === FilterType.AMONG || filterType === FilterType.NOT_AMONG) {
					expressionTail = item.in(new Set((criterion as CriterionNumbers).values));
				} else if (filterType === FilterType.SPECIFIED || filterType === FilterType.UNSPECIFIED) {
					expressionTail = item.in(new Set((criterion as CriterionNumbers).values));
				} else if (filterType === FilterType.CURRENT_USER) {
					expressionTail = item.isCurrentUser();
				}
			}

			if (!expressionTail) {
				throw new Error(`Unhandled combination of category and filter types (were '${CategoryType[filterType]}' and '${FilterType[filterType]}' respectively).`);
			}
		} else if (itemDto instanceof MeasureItemDto) {
			const measureType = itemDto.measureType;

			const item: MeasureItem<any> = (this.createItem(criterion) as MeasureItem<any>);
			this.item = item;

			if (measureType === MeasureType.NUMBER) {
				if (filterType === FilterType.EQUALS || filterType === FilterType.NOT_EQUALS) {
					expressionTail = item.is((criterion as MeasureCriterionNumber).value, null, (criterion as MeasureCriterionNumber).byCategoryId);
				} else if (filterType === FilterType.BETWEEN || filterType === FilterType.NOT_BETWEEN) {
					expressionTail = item.between((criterion as MeasureCriterionNumberRange).valueFrom,
						(criterion as MeasureCriterionNumberRange).valueTo, null, (criterion as MeasureCriterionNumberRange).byCategoryId);
				} else if (filterType === FilterType.LESS_THAN) {
					expressionTail = item.lessThan((criterion as MeasureCriterionNumberRange).valueTo, null,
						(criterion as MeasureCriterionNumberRange).byCategoryId);
				} else if (filterType === FilterType.LESS_THAN_OR_EQUALS) {
					expressionTail = item.lessThanOrEqual((criterion as MeasureCriterionNumberRange).valueTo, null,
						(criterion as MeasureCriterionNumberRange).byCategoryId);
				} else if (filterType === FilterType.GREATER_THAN) {
					expressionTail = item.greaterThan((criterion as MeasureCriterionNumberRange).valueFrom, null,
						(criterion as MeasureCriterionNumberRange).byCategoryId);
				} else if (filterType === FilterType.GREATER_THAN_OR_EQUALS) {
					expressionTail = item.greaterThanOrEqual((criterion as MeasureCriterionNumberRange).valueFrom, null,
						(criterion as MeasureCriterionNumberRange).byCategoryId);
				}
			} else if (measureType === MeasureType.DATE) {
				if (filterType === FilterType.EQUALS || filterType === FilterType.NOT_EQUALS) {
					expressionTail = item.is((criterion as CriterionDate).value);
				} else if (filterType === FilterType.EMPTY) {
					throw new Error('Not implemented!');
				} else if (filterType === FilterType.TODAY) {
					throw new Error('Not implemented!');
				} else if (filterType === FilterType.BETWEEN || filterType === FilterType.NOT_BETWEEN) {
					expressionTail = item.between((criterion as CriterionDateRange).valueFrom, (criterion as CriterionDateRange).valueTo);
				} else if (filterType === FilterType.ON_OR_BEFORE) {
					expressionTail = item.onOrBefore((criterion as CriterionDateRange).valueTo);
				} else if (filterType === FilterType.ON_OR_AFTER) {
					expressionTail = item.onOrAfter((criterion as CriterionDateRange).valueFrom);
				}
			} else if (measureType === MeasureType.TEXT) {
				if (filterType === FilterType.CONTAINS || filterType === FilterType.NOT_CONTAINS) {
					expressionTail = item.contains((criterion as CriterionString).value);
				} else if (filterType === FilterType.BEGINS_WITH) {
					expressionTail = item.startsWith((criterion as CriterionString).value);
				}
			} else if (measureType === MeasureType.MONEY) {
				if (filterType === FilterType.EQUALS || filterType === FilterType.NOT_EQUALS) {
					expressionTail = item.is((criterion as MeasureCriterionMoney).value, (criterion as MeasureCriterionMoney).currencyId,
						(criterion as MeasureCriterionMoney).byCategoryId);
				} else if (filterType === FilterType.BETWEEN || filterType === FilterType.NOT_BETWEEN) {
					expressionTail = item.between((criterion as MeasureCriterionMoneyRange).valueFrom, (criterion as MeasureCriterionMoneyRange).valueTo,
						(criterion as MeasureCriterionMoneyRange).currencyId, (criterion as MeasureCriterionMoneyRange).byCategoryId);
				} else if (filterType === FilterType.LESS_THAN) {
					expressionTail = item.lessThan((criterion as MeasureCriterionMoneyRange).valueTo, (criterion as MeasureCriterionMoneyRange).currencyId,
						(criterion as MeasureCriterionMoneyRange).byCategoryId);
				} else if (filterType === FilterType.LESS_THAN_OR_EQUALS) {
					expressionTail = item.lessThanOrEqual((criterion as MeasureCriterionMoneyRange).valueTo,
						(criterion as MeasureCriterionMoneyRange).currencyId, (criterion as MeasureCriterionMoneyRange).byCategoryId);
				} else if (filterType === FilterType.GREATER_THAN) {
					expressionTail = item.greaterThan((criterion as MeasureCriterionMoneyRange).valueFrom,
						(criterion as MeasureCriterionMoneyRange).currencyId, (criterion as MeasureCriterionMoneyRange).byCategoryId);
				} else if (filterType === FilterType.GREATER_THAN_OR_EQUALS) {
					expressionTail = item.greaterThanOrEqual((criterion as MeasureCriterionMoneyRange).valueFrom,
						(criterion as MeasureCriterionMoneyRange).currencyId, (criterion as MeasureCriterionMoneyRange).byCategoryId);
				}
			} else if (measureType === MeasureType.BOOLEAN) {
				expressionTail = item.is((criterion as CriterionNumber).value);
			}

			if (!expressionTail) {
				throw new Error(`Unhandled combination of measure and filter types (were '${MeasureType[measureType]}' and '${FilterType[filterType]}' respectively).`);
			}
		}

		if (!expressionTail) {
			throw new Error(`Unhandled measure type (was '${itemDto.constructor.name}').`);
		}

		this.expressionTail = expressionTail;
	}

	private createItem(criterion: AbstractCriterion): CategoryItem<any> | MeasureItem<any> {
		PreconditionCheck.notNullOrUndefined(criterion);

		const itemDto: ItemDto = this.filterItem.item;
		let item: CategoryItem<any> | MeasureItem<any> = null;

		if (itemDto instanceof CategoryItemDto) {
			if (!this.expressionTail) {
				item = this.groupBuilder.filterByCategory(itemDto.id);
			} else {
				item = this.expressionTail.andCategory(itemDto.id);
			}
		} else if (itemDto instanceof MeasureItemDto) {
			if (!this.expressionTail) {
				item = this.groupBuilder.filterByMeasure(itemDto.id);
			} else {
				item = this.expressionTail.andMeasure(itemDto.id);
			}
		}

		if (item && criterion.isNegating) {
			item.not();
		}

		return item;
	}

}
