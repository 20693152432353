export abstract class CriterionHelper {

	public static ifNumber(value: any): number | any {
		if (typeof value === 'string' && value.length > 0) {
			return (Number.isFinite(+value) ? +value : value);
		}

		return value;
	}

	public static ifNumbers(values: Array<any>): Array<number> {
		if (Array.isArray(values)) {
			values.forEach((value: any, index: number, items: Array<any>): void => {
				items[index] = CriterionHelper.ifNumber(value);
			});
		}

		return values;
	}

	public static isValidDate(value: any): boolean {
		return value instanceof Date && !Number.isNaN(value.getTime());
	}

	public static ifDate(value: any): Date | any {
		if (typeof value === 'string' && value.length > 0) {
			const date = new Date(value);
			return CriterionHelper.isValidDate(date) ? date : value;
		}
		return value;
	}

}
