import {BooleanExpression} from './boolean-expression';
import {FilterDto} from '../dto/filter-dto';
import {FilterTreeOperatorDto} from '../dto/filter-tree-operator-dto';
import {CategoryItem} from './category-item';
import {MeasureItem} from './measure-item';
import {And} from './and';
import {Or} from './or';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';

/**
 * Created by Hayk.Andriasyan on 5/31/2017.
 */
export class Undecided extends BooleanExpression {

	constructor(_currentFilters: Array<FilterDto>, private _branchOperator: Array<FilterTreeOperatorDto>) {
		super(_currentFilters);
	}

	currentFilter(): FilterDto {
		if (this._currentFilters.length === 0) {
			throw new Error('Somehow there is no currentFilter');
		}

		return this._currentFilters.pop();
	}

	public andCategory(categoryItemDtoId: number|string): CategoryItem<And> {
		PreconditionCheck.notNullOrUndefined(categoryItemDtoId);
		const and: And = new And(this._currentFilters);
		and.operator.children.push(this.currentFilter());
		this._branchOperator.push(and.operator);
		return new CategoryItem<And>(and, categoryItemDtoId);
	}

	public andMeasure(measureItemDtoId: number|string): MeasureItem<And> {
		PreconditionCheck.notNullOrUndefined(measureItemDtoId);
		const and: And = new And(this._currentFilters);
		and.operator.children.push(this.currentFilter());
		this._branchOperator.push(and.operator);
		return new MeasureItem<And>(and, measureItemDtoId);
	}

	public orCategory(categoryItemDtoId: number|string): CategoryItem<Or> {
		PreconditionCheck.notNullOrUndefined(categoryItemDtoId);
		const or: Or = new Or(this._currentFilters);
		or.operator.children.push(this.currentFilter());
		this._branchOperator.push(or.operator);
		return new CategoryItem<Or>(or, categoryItemDtoId);
	}

	public orMeasure(measureItemDtoId: number|string): MeasureItem<Or> {
		PreconditionCheck.notNullOrUndefined(measureItemDtoId);
		const or: Or = new Or(this._currentFilters);
		or.operator.children.push(this.currentFilter());
		this._branchOperator.push(or.operator);
		return new MeasureItem<Or>(or, measureItemDtoId);
	}
}
