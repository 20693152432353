import {CategoryItemDto} from '../item/dto/category-item-dto';
import {CategoryType} from '../item/type/category-type';
import {DateCategoryType} from '../item/type/date-category-type';
import {MeasureItemDto} from '../item/dto/measure-item-dto';
import {MeasureType} from '../item/type/measure-type';
import {ItemDto} from '../item/dto/item-dto';
import {FilterType} from './type/filter-type';
import {FilterTypeOption} from './type/filter-type-option';
import {FilterTypeOptions} from './type/filter-type-options';
import {PreconditionCheck, StringTemplate} from '@synisys/idm-common-util-frontend';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';
import {AbstractCriterion} from '../criterion/abstract-criterion';
import {CriterionValidator} from '../visitor/criterion-validator';
import {ComboboxOptions} from '../../combobox-options';
import {ComboboxOption} from '../../combobox-option';
import {CommonFilterComponent} from '../../../controls/common-filter/common-filter.component';
import {FilterHelper} from '../helper/filter-helper';
import {CategoryValue} from '../item/value/category-value';

export class FilterItem {

	public filterTypeComboboxOptions: FilterTypeOptions;
	public categoryComboboxOptions: ComboboxOptions<ComboboxOption<number, string>>;
	private _isCriterionValidated: boolean;
	private validitySubscription: Subscription;
	private filterTypeComboboxSubscription: Subscription;
	private categoryComboboxSubscription: Subscription;

	constructor(private _parent: CommonFilterComponent, criterionValidator: CriterionValidator, item: ItemDto = null, filterType: FilterType = null) {
		PreconditionCheck.notNullOrUndefined(_parent);
		PreconditionCheck.notNullOrUndefined(criterionValidator);
		PreconditionCheck.notUndefined(item);
		PreconditionCheck.notUndefined(filterType);

		this.clearState();

		this._criterionValidator = criterionValidator;
		this._item = item;
		this._filterType = filterType;

		this.initState();
	}

	private _criterionValidator: CriterionValidator;

	public get criterionValidator(): CriterionValidator {
		return this._criterionValidator;
	}

	private _item: ItemDto;

	public get item(): ItemDto {
		return this._item;
	}

	public set item(item: ItemDto) {
		PreconditionCheck.notUndefined(item);

		this._item = item;

		// when item set to null, validate filters
		if (this._item === null) {
			this.clearState();
			this.resetValiditySubscription();
			this.parent.validate();
		}

		this._filterType = null;
		this.filterType = this.getDefaultFilterType();
		this.resetFilterTypeComboboxSubscription();

		this.clearCategoryComboboxSubscription();
		if (this.item instanceof CategoryItemDto && this.item.dateCategoryType !== DateCategoryType.DAY) {
			this.resetCategoryValuesSubscription(this.item);
		}
	}

	private _filterType: FilterType;

	public get filterType(): FilterType {
		return this._filterType;
	}

	public set filterType(filterType: FilterType) {
		PreconditionCheck.notUndefined(filterType);

		const isInitialChange = !this._filterType;
		if (!isInitialChange && !filterType) {
			filterType = this.getDefaultFilterType();
		}

		this._filterType = filterType;

		this.criterion = this.initCriterion();
		if (!isInitialChange || !this.isFilterValueRequired()) {
			this.validate();
		} else {
			this.criterionValidator.clearValidations();
		}
	}

	private _criterion: AbstractCriterion;

	public get criterion(): AbstractCriterion {
		return this._criterion;
	}

	public set criterion(criterion: AbstractCriterion) {
		this._criterion = criterion;

		this._isCriterionValidated = false;
	}

	public get parent(): CommonFilterComponent {
		return this._parent;
	}

	public get timelineCategoryMessageKey() {
		let messageKey = '';
		const item: ItemDto = this.item;

		if (item instanceof CategoryItemDto) {
			const messageKeyTemplate: StringTemplate = StringTemplate.createTemplate`reporting.controls.filter.text.date.${'dateCategoryType'}s`;

			messageKey = messageKeyTemplate.replaceTemplate({
				'dateCategoryType': DateCategoryType[item.dateCategoryType]
			});
		}

		return messageKey;
	}

	public clearState() {
		this._criterion = null;
		this._isCriterionValidated = false;
		this.clearValiditySubscription();
		this.clearFilterTypeComboboxSubscription();
		this.clearCategoryComboboxSubscription();
		this.filterTypeComboboxOptions = [];
		this.categoryComboboxOptions = [];
	}

	public isForCategory(itemType: CategoryType, filterType: FilterType): boolean {
		PreconditionCheck.notNullOrUndefined(itemType);
		PreconditionCheck.notNullOrUndefined(filterType);

		return this.item instanceof CategoryItemDto && this.item.categoryType === itemType && this.filterType === filterType;
	}

	public isForCategoryTimeline(dateCategoryType: DateCategoryType): boolean {
		PreconditionCheck.notNullOrUndefined(dateCategoryType);

		return this.item instanceof CategoryItemDto && this.item.dateCategoryType === dateCategoryType;
	}

	public isForMeasure(measureType: MeasureType, filterType: FilterType): boolean {
		PreconditionCheck.notNullOrUndefined(measureType);
		PreconditionCheck.notNullOrUndefined(filterType);

		return this.item instanceof MeasureItemDto && this.item.measureType === measureType && (!filterType || this.filterType === filterType);
	}

	public validate(): void {
		if (this.isTemporary()) {
			this.criterionValidator.validity = true;
		} else {
			this.criterion.accept(this.criterionValidator);
		}
	}

	public isTemporary(): boolean {
		let result = true;

		if (this.criterion) {
			result = false;
		}

		return result;
	}

	public requiresCriterionValidation(): boolean {
		return !this.isTemporary() && !this.isCriterionValidated();
	}

	private initState(): void {
		if (!this.filterType) {
			this.filterType = this.getDefaultFilterType();
		}

		this.resetValiditySubscription();
		this.resetFilterTypeComboboxSubscription();

		if (this.item instanceof CategoryItemDto && this.item.dateCategoryType !== DateCategoryType.DAY) {
			this.resetCategoryValuesSubscription(this.item);
		}

		// Validate initial state.
		if (this.item || this.filterType) {
			const isValid = this.filterTypeComboboxOptions.some((option: FilterTypeOption): boolean => {
				return option.value === this.filterType;
			});

			if (!isValid) {
				throw new Error('Provided \'filterType\' is not compatible with provided \'item\'.');
			}
		}
	}

	private getDefaultFilterType(): FilterType {
		let defaultOption: FilterTypeOption = null;

		if (this.item instanceof CategoryItemDto) {
			defaultOption = FilterTypeOptions.defaultOfCategory(this.item.categoryType, this.item.dateCategoryType);
		} else if (this.item instanceof MeasureItemDto) {
			defaultOption = FilterTypeOptions.defaultOfMeasure(this.item.measureType);
		}

		return defaultOption ? defaultOption.value : null;
	}

	private initCriterion(): AbstractCriterion {
		if (!this.item || !this.filterType || !this.criterionValidator) {
			return null;
		}

		return FilterHelper.criterionFor(this);
	}

	private clearValiditySubscription(): void {
		if (this.validitySubscription) {
			this.validitySubscription.unsubscribe();
		}

		this.validitySubscription = null;
	}

	private resetValiditySubscription(): void {
		this.clearValiditySubscription();

		this.validitySubscription = this.criterionValidator.isValid
			.subscribe((isValid: boolean): void => {
				if (!this.isTemporary()) {
					this._isCriterionValidated = true;
				}
			});
	}

	private clearFilterTypeComboboxSubscription(): void {
		if (this.filterTypeComboboxSubscription) {
			this.filterTypeComboboxSubscription.unsubscribe();
		}

		this.filterTypeComboboxSubscription = null;
	}

	private resetFilterTypeComboboxSubscription(): void {
		this.clearFilterTypeComboboxSubscription();

		this.filterTypeComboboxOptions = this.initFilterTypeComboboxOptions();

		const messageObservables: Array<Observable<string>> = this.filterTypeComboboxOptions
			.map((option: FilterTypeOption): Observable<string> => {
				return this.parent.messageService.getMessage(option.name);
			});

		this.filterTypeComboboxSubscription = Observable
			.zip(
				this.parent.currentLanguageProvider.getCurrentLanguage(),
				...messageObservables
			)
			.subscribe((): void => {// Zip results are discarded as we just need to wait for those to be resolved.
				this.filterTypeComboboxOptions = this.filterTypeComboboxOptions
					.map((option: FilterTypeOption): FilterTypeOption => {
						return FilterTypeOption.from(option);
					});
			});
	}

	private initFilterTypeComboboxOptions(): FilterTypeOptions {
		let options: FilterTypeOptions = [];

		if (this.item instanceof CategoryItemDto) {
			options = FilterTypeOptions.ofCategory(this.item.categoryType, this.item.dateCategoryType);
		} else if (this.item instanceof MeasureItemDto) {
			options = FilterTypeOptions.ofMeasure(this.item.measureType);
		}

		return options;
	}

	private clearCategoryComboboxSubscription(): void {
		if (this.categoryComboboxSubscription) {
			this.categoryComboboxSubscription.unsubscribe();
		}

		this.categoryComboboxSubscription = null;
	}

	private resetCategoryComboboxSubscription(itemId: number|string): void {
		PreconditionCheck.notNullOrUndefined(itemId);

		this.clearCategoryComboboxSubscription();

		this.categoryComboboxSubscription = this.parent.valueLoader.getCategoryValues(itemId)
			.subscribe((values: Array<CategoryValue>): void => {
				this.categoryComboboxOptions = CommonFilterComponent.initSimpleComboboxOptions(values);
			});
	}

	private resetCategoryValuesSubscription(itemDto: CategoryItemDto): void {
		PreconditionCheck.notNullOrUndefined(itemDto);

		this.categoryComboboxOptions = [];

		if (!itemDto.searchable) {
			this.resetCategoryComboboxSubscription(itemDto.id);
		}
	}

	private isCriterionValidated(): boolean {
		return this._isCriterionValidated;
	}

	private isFilterValueRequired(): boolean {
		switch (this.filterType) {
			case FilterType.CURRENT:
			case FilterType.CURRENT_USER:
			case FilterType.NOT_CURRENT:
			case FilterType.PREVIOUS:
			case FilterType.NEXT:
			case FilterType.SPECIFIED:
			case FilterType.UNSPECIFIED: {
				return false;
			}
			default: {
				return true;
			}
		}
	}
}
