import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import {CriterionNumberRange} from './criterion-number-range';
import {AbstractMeasureCriterion} from './abstract-measure-criterion';
import {RangeBounding} from './range-bounding';
import {FilterItem} from '../filter/filter-item';

/**
 * For money measure 'between', 'less than', 'less than or equals', 'greater than', 'greater than or equals' etc.
 */
export class MeasureCriterionNumberRange extends CriterionNumberRange implements AbstractMeasureCriterion {

	constructor(filterItem: FilterItem, isNegating?: boolean, isIncluding?: boolean, bounding?: RangeBounding, valueFrom?: number, valueTo?: number, private _byCategoryId: number|string = null) {
		super(filterItem, isNegating, isIncluding, bounding, valueFrom, valueTo);

		PreconditionCheck.notUndefined(_byCategoryId);
	}

	public get byCategoryId(): number|string {
		return this._byCategoryId;
	}

	public set byCategoryId(byCategoryId: number|string) {
		this._byCategoryId = byCategoryId;

		this.validate();
	}

}
