import {Observable} from 'rxjs/Observable';
import {CategoryValue} from '../item/value/category-value';
import {CurrencyValue} from '../item/value/currency-value';

export abstract class ValueLoader {
	abstract getCategoryValues(id: number|string): Observable<Array<CategoryValue>>;

	abstract getCurrencies(): Observable<Array<CurrencyValue>>;
}

export class EmptyValueLoader extends ValueLoader {

	getCategoryValues(id: number|string): Observable<Array<CategoryValue>> {
		return Observable.from([]);
	}

	getCurrencies(): Observable<Array<CurrencyValue>> {
		return Observable.from([]);
	}
}
